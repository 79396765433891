import {useState} from "react";
import TableHead, {TableColumn} from "../atom/table/TableHead";
import {useDebounce} from "../../../hooks/useDebounce";
import {useNavigate} from "react-router-dom";
import {Customer} from "../interfaces/Customer";
import client from "../../../axios";
import MasterFooter from "../molecule/MasterFooter";
import Table from "../atom/table/Table";
import CustomersTableBody from "../molecule/CustomersTableBody";
import InfoModal from "../../../components/common-modals/info-modal";

const columns: TableColumn[] = [
  '거래처번호',
  '거래처명'
];

export default function CustomersDisplay() {
  const navigate = useNavigate();
  const [data, setData] = useState<Customer[]>([]);
  const [addedData, setAddedData] = useState<Customer[]>([]);
  const [searchData, setSearchData] = useState("");
  const [modifiedIndex, setModifiedIndex] = useState<number[]>([]);
  const debouncedText = useDebounce(searchData, 300);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailed, setOpenFailed] = useState(false);

  const defaultData: Customer = {
    cus_no: "",
    corp: "",
    corp_nm: "",
    serv_seq: 1,
  }

  async function reload() {
    setAddedData([]);
    setOpenSuccess(false);
    const res = await client.get('/api/master/customer', { params: { query: searchData } });
    setData(res.data);
  }

  function handleModify(newIndex: number) {
    if (modifiedIndex.findIndex(i => i === newIndex) === -1) {
      setModifiedIndex(prev => [...prev, newIndex]);
    }
  }

  async function handleAdd() {
    setAddedData(prev => [...prev, defaultData]);
  }

  async function handleLogout() {
    await client.delete('/api/auth/logout');
    navigate('/login');
  }

  async function handleSave() {
    try {
      await client.put('/api/master/customer', {
        modified: modifiedIndex.map(mIndex => data[mIndex]),
        added: addedData
      });
      setOpenSuccess(true);
    } catch(e) {
      setOpenFailed(true);
    }
  }

  return (
    <>
      <Table width={500}>
        <TableHead columns={columns} />
        <CustomersTableBody
          data={data}
          setData={setData}
          addedData={addedData}
          setAddedData={setAddedData}
          searchData={debouncedText}
          onModify={handleModify}
        />
      </Table>
      <p className='mb-2 text-secondary'>클릭하여 더 자세한 정보를 알 수 있습니다.</p>
      <MasterFooter
        searchData={searchData}
        setSearchData={setSearchData}
        handleClickAdd={handleAdd}
        handleClickSave={handleSave}
        handleClickLogOut={handleLogout}
      />
      <InfoModal
        show={openSuccess}
        message="성공적으로 저장하였습니다."
        onButtonClick={() => {
          setOpenSuccess(false);
          reload();
        }}
      />
      <InfoModal
        show={openFailed}
        message="저장에 실패하였습니다."
        onButtonClick={() => {
          setOpenFailed(false);
          reload();
        }}
      />
    </>
  );
}