import React, {useState, useEffect, useContext} from "react";
import {
    AddModModalListProps,
    AddModModalRowProps, IProdPerf
} from "../../../interfaces/production-manage/i-prod-perf";
import {InfoModalContext, NewRowContext, ProdPerfContext, SelectedProdPerfContext} from "./add-mod-modal-context";
import {accCategory} from "../../../../lib/common-args";
import {defaultNewRow} from "./add-mod-modal";
import {IProductData} from "../../../interfaces/stock-manage/i-initial-stock";
import ProductModal from "./product-modal";
import client from "../../../../axios";
import {Modal} from "react-bootstrap";
import {start} from "repl";
import {shallowEqual, useSelector} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";
import {convertReadable} from "../../../../lib/converting-func";

const AddModModalList = ({ data, clickedProdPerf }: AddModModalListProps) => {

    /* 받아온 컨텍스트 */
    const prodPerfContext = useContext(ProdPerfContext)
    const newRowContext = useContext(NewRowContext)
    const infoModalContext = useContext(InfoModalContext)
    const selectedProdPerfContext = useContext(SelectedProdPerfContext)

    const {dec} = useSelector((state: RootReducer) => state.dec, shallowEqual)

    /* 포커싱 된 행의 정보 */
    const [ focusedIndex, setFocusedIndex ] = useState(-1)

    return (
        <>
            {data.map((element, index) =>
                <AddModModalRow key={index} row={element} focusedIndex={focusedIndex} setFocusedIndex={setFocusedIndex} clickedProdPerf={clickedProdPerf} dec={dec}
                                ppContext={prodPerfContext} nrContext={newRowContext} imContext={infoModalContext} spContext={selectedProdPerfContext} />

            )}
        </>
    )
}

const AddModModalRow = ({ row, focusedIndex, setFocusedIndex, ppContext, nrContext, imContext, spContext, clickedProdPerf, dec }: AddModModalRowProps) => {

    /* 현재 포커싱 되어있는 셀 */
    const [ focusMode, setFocusMode ] = useState({
        item_nm: false,
        qty: false,
        bigo: false
    })
    /* 품목 모달의 초기 데이터 */
    const [ initialProductData, setInitialProductData ] = useState('')
    /* 행 체크 */
    const [ checked, setChecked ] = useState(false)
    /* 품목 검색 모달 가져오기 */
    const [ showProductModal, setShowProductModal ] = useState(false)
    /* 레시피가 있는가 확인 */
    const [ showIsThereRecipe, setShowIsThereRecipe ] = useState(false)
    /* 에휴 */
    const [ _addingData, setAddingData ] = useState<IProductData[]>([])

    /* focusedIndex가 바뀔 시 그 행에 포인트 */
    useEffect(() => {
        if(row.index === focusedIndex) {
            if(!focusMode.item_nm && !focusMode.qty && !focusMode.bigo) {
                setFocusMode({ ...focusMode, item_nm: true})
            }
        }
    }, [focusedIndex])

    /* 행 선택 시 선택배열에 넣기 */
    useEffect(() => {
        if(row.index !== -1) {
            if(checked) {
                if(spContext.selectedProdPerf.findIndex(elem => elem.item === row.item) === -1) {
                    spContext.setSelectedProdPerf(spContext.selectedProdPerf.concat(row))
                }
            } else {
                if(spContext.selectedProdPerf.findIndex(elem => elem.item === row.item) !== -1) {
                    spContext.setSelectedProdPerf(spContext.selectedProdPerf.filter(elem => elem.item !== row.item))
                }
            }
        }
    }, [row.index, checked])
    
    useEffect(() => {
        if(spContext.selectedProdPerf.length === 0)
            setChecked(false)
    }, [spContext.selectedProdPerf.length])

    /* 테이블 셀 선택과 블러 */
    const handleClickOnItemNmCell = () => {
        setFocusedIndex(row.index)
        setFocusMode({ item_nm: true, qty: false, bigo: false})
    }
    const handleClickOnQtyCell = () => {
        setFocusedIndex(row.index)
        setFocusMode({ item_nm: false, qty: true, bigo: false})
    }
    const handleClickOnBigoCell = () => {
        setFocusedIndex(row.index)
        setFocusMode({ item_nm: false, qty: false, bigo: true})
    }
    const handleBlurOnItemNmCell = () => {
        setFocusMode({ ...focusMode, item_nm: false })
    }
    const handleBlurOnQtyCell = () => {
        setFocusMode({ ...focusMode, qty: false })
    }
    const handleBlurOnBigoCell = () => {
        setFocusMode({ ...focusMode, bigo: false })
    }

    /* item_nm */
    const handleChangeOnItemNm = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        if(index === -1) {
            nrContext.setNewRow({ ...nrContext.newRow, item_usr: '', item_nm: e.target.value })
        } else {
            ppContext.setProdPerf(ppContext.prodPerf.map(element => {
                return (index === element.index)
                    ?
                    { ...element, item_usr: '', item_nm: e.target.value, item: '' }
                    :
                    element
            }))
        }
    }


    const handleEnterOnItemNm = async (e: any, index: number) => {
        if(e.key === 'Enter' && row.item_usr === '') {
            const filteredProductData = ppContext.productData.filter(pd_elem =>
                pd_elem.item_nm.toLowerCase().includes(e.target.value.toLowerCase()) ||
                pd_elem.item_usr.includes(e.target.value)
            )

            if((e.target.value.length === 0) || filteredProductData.length === 0) {
                /* 입력란의 문자 개수가 1개 이하이거나 입력란에 따라 찾을 수 없다면 */
                setInitialProductData('')
                setShowProductModal(true)
            } else if (filteredProductData.length >= 2) {
                /* 입력란의 검색 결과가 2개 이상이라면 */
                setInitialProductData(e.target.value)
                setShowProductModal(true)
            } else {
                const addingData = filteredProductData[0]
                setAddingData([addingData])
                const isOverlap = ppContext.prodPerf
                    .findIndex(pp_elem => clickedProdPerf.ymd === row.ymd && clickedProdPerf.code === row.code
                        && clickedProdPerf.item === addingData.item && pp_elem.item_usr !== '') != -1

                if(isOverlap) {
                    imContext.setFailedTitle('입력 실패')
                    imContext.setFailedMessage('이미 중복되는 품목이 있습니다.')
                    imContext.setShowFailedModal(true)
                    setInitialProductData('')

                    if(index === -1) {
                        nrContext.setNewRow({ ...defaultNewRow, ymd: row.ymd, code: row.code })
                    } else {
                        ppContext.setProdPerf(ppContext.prodPerf.map(elem =>
                            (elem.index === row.index)
                                ?
                                { ...elem, item: '', item_usr: '', item_nm: '' }
                                :
                                elem
                        ))
                    }
                } else {
                    try {
                        const response = await client.post('/api/production-manage/is-there-recipe', {
                            item_p: [addingData.item]
                        })

                        if(response.data.result) {
                            addData(addingData, index)
                            setTimeout(() => {
                                setFocusMode({ item_nm: false, qty: true, bigo: false })
                                setInitialProductData('')
                            }, 50)
                        } else {
                            setShowIsThereRecipe(true)
                        }
                    } catch (e: any) {

                    }

                }
            }
            e.preventDefault()
        } else if(e.key === 'Enter' && row.item_usr !== '') {
            setFocusMode({ item_nm: false, qty: true, bigo: false })
            e.preventDefault()
        }
    }

    /* 추가 진행 */
    const addData = (addingData: IProductData, index: number) => {
        ppContext.setIsChanged(true)
        if(index === -1) {

            const newIndex = getNewIndex()
            ppContext.setProdPerf(ppContext.prodPerf.concat({
                ...row,
                index: newIndex,
                ymd: clickedProdPerf.ymd,
                code: clickedProdPerf.code,
                seq: getNewSeqNum(clickedProdPerf.code),
                item: addingData.item,
                item_usr: addingData.item_usr,
                item_nm: addingData.item_nm,
                spec: addingData.spec,
                unit: addingData.unit,
                unit_nm: addingData.unit_nm,
                acc_gbn: addingData.acc_gbn
            }))
            ppContext.setBeforeProdPerf(ppContext.beforeProdPerf.concat({
                index: newIndex,
                ymd: '',
                item: '',
                code: ''
            }))
            nrContext.setNewRow({
                ...defaultNewRow,
                ymd: row.ymd,
                code: row.code
            })
            setFocusedIndex(newIndex)
        } else {
            ppContext.setProdPerf(ppContext.prodPerf.map(elem =>
                (elem.index === index)
                    ?
                    {
                        ...elem,
                        item: addingData.item,
                        item_usr: addingData.item_usr,
                        item_nm: addingData.item_nm,
                        spec: addingData.spec,
                        unit: addingData.unit,
                        unit_nm: addingData.unit_nm
                    }
                    :
                    elem
            ))
        }
    }

    /* 여러 품목 동시에 추가 */
    const addMultipleData = (multipleAddingData: IProductData[], startIndex: number) => {
        const newIndex = getNewIndex()
        const newSeq = getNewSeqNum(clickedProdPerf.code)
        const reducedSelectedData =
            multipleAddingData.reduce((prev, cur, index) => {
                return prev.concat({
                    ...cur,
                    index: newIndex + index,
                    ymd: clickedProdPerf.ymd,
                    code: clickedProdPerf.code,
                    seq: newSeq + index,
                    qty: '',
                    qty_bad: '',
                    qty_mix: '',
                    bigo: ''
                })
            }, [] as IProdPerf[])

        if(row.index === -1) {
            ppContext.setProdPerf(ppContext.prodPerf.concat(reducedSelectedData))
            ppContext.setBeforeProdPerf(ppContext.beforeProdPerf.concat(
                reducedSelectedData.map(rsd => ({
                    index: rsd.index,
                    ymd: '',
                    code: '',
                    item: ''
                }))
            ))
            setFocusedIndex(newIndex)
        } else {
            const newData = reducedSelectedData[0]
            ppContext.setProdPerf(ppContext.prodPerf.map(elem =>
                (startIndex === elem.index)
                    ?
                    {
                        ...elem,
                        item: newData.item,
                        item_usr: newData.item_usr,
                        item_nm: newData.item_nm,
                        spec: newData.spec,
                        unit: newData.unit,
                        unit_nm: newData.unit_nm
                    }
                    :
                    elem
            ))
        }
        ppContext.setIsChanged(true)
        setShowProductModal(false)
        setTimeout(() => {
            setFocusMode({ item_nm: false, qty: true, bigo: false })
        }, 200)
    }

    /* qty */
    const handleChangeOnQty = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        ppContext.setIsChanged(true)
        if(index === -1 ) {
            nrContext.setNewRow({ ...nrContext.newRow, qty: e.target.value })
        } else {
            ppContext.setProdPerf(ppContext.prodPerf.map(element => {
                return (element.index === index) ?
                    { ...element, qty: e.target.value }
                    :
                    element
            }))
        }
        ppContext.setIsChanged(true)
    }
    const handleBlurOnQty = (e: React.FocusEvent<HTMLInputElement>, index: number) => {
        const tmp = parseFloat(e.target.value.replaceAll(/[^0-9.]+/g, ''))
        const modifiedQty = isNaN(tmp) ? '' : tmp.toString()

        if(index === -1) {
            nrContext.setNewRow(
                {
                    ...nrContext.newRow, qty:
                        (e.target.value === '')
                            ?
                            ''
                            :
                            modifiedQty
                })
        } else {
            ppContext.setProdPerf(ppContext.prodPerf.map(element => {
                return (element.index === index)
                    ?
                    {
                        ...element, qty:
                            (e.target.value === '')
                                ?
                                ''
                                :
                                modifiedQty
                    }
                    :
                    element
            }))
        }
    }
    const handleEnterOnQty = (e: any) => {
        if(e.key === 'Enter') {
            setFocusMode({ item_nm: false, qty: false, bigo: true })
        }
    }

    /* bigo */
    const handleChangeOnBigo = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        if(index === -1 ) {
            nrContext.setNewRow({ ...nrContext.newRow, bigo: e.target.value })
        } else {
            ppContext.setProdPerf(ppContext.prodPerf.map(element => {
                return (element.index === index) ?
                    { ...element, bigo: e.target.value }
                    :
                    element
            }))
        }
    }
    const handleEnterOnBigo = (e: any) => {
        if(e.key === 'Enter') {
            setFocusedIndex(getNextFocusingCell())
            setFocusMode({ item_nm: false, qty: false, bigo: false })
        }
    }

    /* 새로 추가했을 때  */
    const getNewIndex = () => {
        return ppContext.prodPerf.length
    }

    /* 새 seq 받아오기 */
    const getNewSeqNum = (code: string) => {
        return ppContext.prodPerf.filter(elem => elem.code === code).length + 1
    }

    /* 다음 포커싱 행 찾기 */
    const getNextFocusingCell = () => {
        let flag: boolean = false, currentIndex: number = row.index, nextIndex: number = 0

        if(currentIndex === -1) return -1

        for(let i = currentIndex; i < ppContext.prodPerf.length; i++) {
            if(ppContext.prodPerf[i].ymd === clickedProdPerf.ymd && ppContext.prodPerf[i].code === clickedProdPerf.code && ppContext.prodPerf[i].index > currentIndex) {
                flag = true
                nextIndex = i
                break
            }
        }

        return flag ? nextIndex : -1
    }

    const handleSubmit = async (startIndex: number, selectedData: IProductData[]) => {
        setAddingData(selectedData)
        try {
            const response = await client.post('/api/production-manage/is-there-recipe', {
                item_p: selectedData.map(element => element.item)
            })

            if(response.data.result) {
                addMultipleData(selectedData, startIndex)
            } else {
                setShowIsThereRecipe(true)
            }
        } catch (e: any) {

        }
    }

    const handleSaveDataWhenThereIsRecipe = () => {
        nrContext.setNewRow({ ...nrContext.newRow, item_nm: '' })
        setShowIsThereRecipe(false)
        addMultipleData(_addingData, row.index)
    }

    return (
        <>
            <tr>
                <th scope='row' style={{ textAlign: 'center'}}>
                    <input className="form-check-input" type="checkbox" checked={checked} onChange={() => setChecked(!checked)} />
                </th>
                <td style={{ textAlign: 'center' }}>{row.seq === -1 ? '' : row.seq}</td>
                <td style={{ textAlign: 'center' }}>{row.item_usr}</td>
                <td
                    onClick={handleClickOnItemNmCell}
                    onBlur={handleBlurOnItemNmCell}
                >
                    {
                        focusMode.item_nm
                            ?
                            <input type="text" className="form-control form-control-sm shadow-none"
                                   value={row.item_nm} autoFocus
                                   onChange={e => handleChangeOnItemNm(e, row.index)}
                                   onKeyDown={e => handleEnterOnItemNm(e, row.index)}
                            />
                            :
                            row.item_nm
                    }
                </td>
                <td>{row.spec}</td>
                <td style={{ textAlign: 'center' }}>{row.unit_nm}</td>
                <td style={{ textAlign: 'end' }}
                    onClick={handleClickOnQtyCell}
                    onBlur={handleBlurOnQtyCell}
                >
                    {
                        focusMode.qty
                            ?
                            <input type="text" className="form-control form-control-sm shadow-none" style={{ textAlign: 'end', width: '90px' }}
                                   value={row.qty} autoFocus
                                   onKeyDown={handleEnterOnQty}
                                   onChange={e => handleChangeOnQty(e, row.index)}
                                   onBlur={e => handleBlurOnQty(e, row.index)}
                            />
                            :
                            convertReadable(row.qty, dec)
                    }
                </td>
                <td style={{ textAlign: 'center' }}>{accCategory.find((element) => element.value === row.acc_gbn)?.name}</td>
                <td
                    onClick={handleClickOnBigoCell}
                    onBlur={handleBlurOnBigoCell}
                >
                    {
                        focusMode.bigo
                            ?
                            <input type="text" className="form-control form-control-sm shadow-none"
                                   value={row.bigo} autoFocus
                                   onKeyDown={handleEnterOnBigo}
                                   onChange={e => handleChangeOnBigo(e, row.index)}
                            />
                            :
                            row.bigo
                    }
                </td>
            </tr>
            
            <ProductModal show={showProductModal} onHide={() => setShowProductModal(false)} onSubmit={handleSubmit}
                          clickedProdPerf={clickedProdPerf} ppContext={ppContext} nrContext={nrContext}
                          initialSearchData={initialProductData} startIndex={row.index} mode={row.index === -1 ? 1 : 0} />

            {/* 변경 내용 저장 모달 */}
            <Modal show={showIsThereRecipe} onHide={() => {
                setShowIsThereRecipe(false)
            }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        레시피 없음
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    레시피가 존재하지 않는 품목이 있습니다.<br/>그래도 진행하시겠습니까?
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-danger my-2" onClick={() => {
                        setShowIsThereRecipe(false)
                        nrContext.setNewRow({ ...nrContext.newRow, item_nm: '' })
                    }}>아니오</button>
                    <button className="btn btn-primary my-2" onClick={handleSaveDataWhenThereIsRecipe}>예</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddModModalList