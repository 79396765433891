import React, {useEffect, useMemo, useRef, useState} from 'react'
import client from '../../../../axios'
import {ProductList} from "./product-list"
import './styles.css'
import AddModal from "./modals/add-modal";
import {IPrdStandardCode, IProduct} from '../../../interfaces/standard-info/i-product'
import {IColumn, INameValue} from '../../../interfaces/common'
import InfoModal from "../../../common-modals/info-modal"
import {accCategory} from '../../../../lib/common-args'
import * as buttonActions from "../../../../redux/actions/button";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";
import {range} from "../../../../lib/util-func";


/*
* 사용 구분
* 전체: 보이는 것->0, 실제 값->2
* 사용: 보이는 것->1, 실제 값->1
* 미사용: 보이는 것->2, 실제 값->0
* */

/*
* 계정 구분
* 보이는 것: 상품, 원재료, ...
* 변수 접근 시 실제 값: 5자리의 코드
* */

const Product = () => {

  const refs = [useRef<HTMLInputElement>(null)]

  /* 테이블 헤더 속성 */
  const productTableHeaders = useMemo(() => [
    {name: '코드', style: {width: '60px', minWidth: '50px', textAlign: 'center'}},
    {name: '품명', style: {width: '300px', minWidth: '200px', textAlign: 'center'}},
    {name: '규격', style: {width: '300px', minWidth: '200px', textAlign: 'center'}},
    {name: '계정구분', style: {width: '70px', minWidth: '70px', textAlign: 'center'}},
    {name: '단위', style: {width: '50px', minWidth: '50px', textAlign: 'center'}},
    {name: '사용여부', style: {width: '70px', minWidth: '70px', textAlign: 'center'}},
    {name: '', style: {}}
  ] as IColumn[], [])

  /* 사용여부 구분 */
  const useCategory = [
    {name: '전체', value: '2'},
    {name: '사용', value: '1'},
    {name: '미사용', value: '0'}
  ] as INameValue[]

  /* 제품의 정보를 담는 배열 */
  const [productList, setProductList] = useState<IProduct[]>([])
  /* 검색란의 계정구분 창이 가질 값 */
  const [accGbnOpt, setAccGbnOpt] = useState('00000')
  /* 사용여부 창이 가질 값 */
  const [useGbnOpt, setUseGbnOpt] = useState('1')
  /* 사용자의 기준코드 값 중 sml, sml_nm만 불러옴 */
  const [codeData, setCodeData] = useState<IPrdStandardCode[]>([])
  /* 품목/규격 검색란이 가질 값 */
  const [searchData, setSearchData] = useState('')
  /* 품목 추가 모달 보일지 결정 */
  const [showAddModal, setShowAddModal] = useState(false)
  const [modalFailed, setModalFailed] = useState(false)
  const [failedMessage, setFailedMessage] = useState('');

  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  /* redux에서 버튼 액션 불러오기 */
  const btnActions = useSelector((state: RootReducer) => state.buttonActions, shallowEqual)
  const dispatch = useDispatch()

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  /* 초기 실행 시  */
  useEffect(() => {
    refs[0].current?.focus()
    fetchProductData(searchData, accGbnOpt, useGbnOpt)
  }, [])


  /* 버튼 리스너 */
  useEffect(() => {
    if (btnActions.active) {
      if (btnActions.action === 'input') {
        setShowAddModal(true)
      } else if (btnActions.action === 'search') {
        fetchProductData(searchData, accGbnOpt, useGbnOpt)
      } else if (btnActions.action === 'remove') {
        setOpenConfirmModal(true)
      }
    }
    dispatch(buttonActions.clear())
  }, [btnActions.action, btnActions.active, dispatch])

  /* 수정 수행하는 함수 */
  const handleModification = async (row: IProduct, code: string) => {
    /* 하위 컴포넌트 중 ProductRow 에서 받아온 값을 토대로 수정 수행 */
    try {
      const response = await client.post('/api/standard-info/mod-product-data', {row})
      if (response.data.result === 'expired') {
        setModalFailed(true)
        setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
      } else if (response.data.result === 'failed') {
        setModalFailed(true)
        setFailedMessage('서버에 문제가 발생하였습니다.')
      } else {
        setProductList(productList.map(product =>
          (product.item === code) ?
            row :
            product
        ))
      }
    } catch (e: any) {
      setModalFailed(true)
      setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
    }
  }

  /* 추가 수행하는 함수 */
  const handleAdd = async (row: IProduct) => {
    try {
      const response = await client.post('/api/standard-info/add-product-data', {row})
      if (response.data.result === 'expired') {
        setModalFailed(true)
        setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
      } else if (response.data.result === 'failed') {
        setModalFailed(true)
        setFailedMessage('서버에 문제가 발생하였습니다.')
      } else {
        fetchProductData(searchData, accGbnOpt, useGbnOpt);
      }
    } catch (e) {
      setModalFailed(true)
      setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
    }
  }

  const fetchProductData = (item_nm: string, acc_gbn: string, use_gbn: string) => {
    setAllSelected(false);
    setSelectedRows([]);

    client.post('/api/standard-info/get-product-data', {
      payload: {item_nm, acc_gbn, use_gbn}
    })
      .then(res => {
        if (res.data.result === 'expired') {
          setModalFailed(true)
          setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
        } else if (res.data.result === 'failed') {
          setModalFailed(true)
          setFailedMessage('서버에 문제가 발생하였습니다.')
        } else {
          const productData = res.data.payload.rowsProduct as IProduct[]
          setCodeData(res.data.payload.rowsCode as IPrdStandardCode[])
          setProductList(productData.map(product => ({
            ...product,
            spec: product.spec || '',
          })));
        }
      })
      .catch(() => {
        setModalFailed(true)
        setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
      })
  }

  function handleAllSelected() {
    if (allSelected) {
      setSelectedRows([]);
    } else {
      setSelectedRows(range(0, productList.length));
    }

    setAllSelected(!allSelected);
  }

  function handleRowCheckboxChange(index: number) {
    const newSelectedRows = [...selectedRows];
    if (newSelectedRows.includes(index)) {
      newSelectedRows.splice(newSelectedRows.indexOf(index), 1);
    } else {
      newSelectedRows.push(index);
    }

    setSelectedRows(newSelectedRows);
    setAllSelected(newSelectedRows.length === productList.length);
  }

  async function handleDeleteData() {
    setOpenConfirmModal(false);
    try {
      await client.post('/api/standard-info/delete-product-data', {
        payload: selectedRows.map(rowIndex => productList[rowIndex])
      });
      setOpenSuccessModal(true);
      fetchProductData(searchData, accGbnOpt, useGbnOpt);
    } catch (e) {
      setModalFailed(true)
      setFailedMessage('서버에 문제가 발생하였습니다.')
    }

  }

  return (
    <>
      {/* 위쪽 메뉴 */}
      <div className="d-flex mt-2 me-4">
        <div className="flex-grow-1 d-flex ms-3 align-items-center">
          {/* 품명/규격 기반 입력창 */}
          <div className="d-flex align-content-center mx-3">
            <label className="col-auto align-items-center my-1 mx-1">품명</label>
            <div className="input-group input-group-sm">
              <input ref={refs[0]} type="text" className="form-control" value={searchData}
                     onChange={e => setSearchData(e.target.value)}/>
            </div>
          </div>

          {/* 계정구분 선택창 */}
          <div className="d-flex align-content-center mx-3">
            <label className="col-auto align-items-center my-1 mx-1">계정구분</label>
            {/* 옵션이 바뀌면 자동으로 표시 리스트 갱신 */}
            <select defaultValue="0" className="form-select form-select-sm"
                    onChange={e => setAccGbnOpt(e.target.value)}>
              {accCategory.map((category, index) =>
                <option key={index} value={category.value}>{`${index}. ${category.name}`}</option>
              )}
            </select>
          </div>

          {/* 사용여부 선택창 */}
          <div className="d-flex align-content-center mx-3">
            <label className="col-auto align-items-center my-1 mx-1">사용여부</label>
            {/* 옵션이 바뀌면 자동으로 표시 리스트 갱신 */}
            <select defaultValue="1" className="form-select form-select-sm"
                    onChange={e => setUseGbnOpt(e.target.value)}>
              {useCategory.map((category, index) =>
                <option key={index} value={category.value}>{`${index}. ${category.name}`}</option>
              )}
            </select>
          </div>
        </div>
      </div>

      {/* 품목 테이블 */}
      <div className="justify-content-center scrollbar my-3 mx-3 scrollbar table-wrapper">
        <table className="table table-sm table-bordered table-hover table-condensed">
          {/* 테이블 헤더 */}
          <thead className="sticky-head">
          <tr>
            <th style={{ width: 30, textAlign: "center" }}>
              <input
                type="checkbox"
                className="form-check-input"
                checked={allSelected}
                onChange={handleAllSelected}
              />
            </th>
            {productTableHeaders.map((value, index) =>
              <th style={value.style} key={`pdhead-${index + 1}`} scope="col">{value.name}</th>
            )}
          </tr>
          </thead>

          {/* 테이블 바디 */}
          <tbody>
          {/* 검색창의 조건에 따라 전체 리스트를 필터링해서 보여줌 */}
          <ProductList
            data={productList}
            accCategory={accCategory}
            useCategory={useCategory}
            codeData={codeData}
            handleModification={handleModification}
            selectedRows={selectedRows}
            handleRowCheckboxChange={handleRowCheckboxChange}
          />
          </tbody>
        </table>
      </div>

      <AddModal show={showAddModal}
                onHide={() => setShowAddModal(false)}
                accCategory={accCategory}
                useCategory={useCategory}
                codeData={codeData}
                onSubmit={handleAdd}/>

      {/* 정보저장 실패 모달 */}
      <InfoModal show={modalFailed} onHide={() => setModalFailed(false)} title={"정보 저장 실패"}
                 message={failedMessage} onButtonClick={() => setModalFailed(false)}/>

      <InfoModal
        show={openConfirmModal}
        message={'선택한 메뉴를 삭제하시겠습니까?'}
        onButtonClick={handleDeleteData}
      />

      <InfoModal
        show={openSuccessModal}
        message={'메뉴를 성공적으로 삭제하였습니다.'}
        onButtonClick={() => setOpenSuccessModal(false)}
      />

    </>
  )
}

export default Product