import {Modal} from "react-bootstrap";
import SecondaryButton from "../../atom/buttons/SecondaryButton";
import PrimaryButton from "../../atom/buttons/PrimaryButton";
import {BasicModalProps} from "../../interfaces/BasicModalProps";
import {Account} from "../../interfaces/Account";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Column, ColumnLeft, ColumnRight} from "../../../../components/atom/Columns";
import {Customer} from "../../interfaces/Customer";
import client from "../../../../axios";

interface ModifyAccountModalProps extends BasicModalProps {
  editingCellRow: number | null;
  data: Account[];
  setData: (data: Account[]) => void;
  addedData: Account[];
  setAddedData: (data: Account[]) => void;
  onModify: (row: number) => void;
}

export default function ModifyAccountModal({ editingCellRow, data, setData, addedData, setAddedData, onModify, ...props }: ModifyAccountModalProps) {
  const isAdded = useMemo(() => editingCellRow != null && editingCellRow >= data.length, [editingCellRow, data.length]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  function initialize() {
    setSelectedCustomer(null);
  }

  function handleSelectCustomer() {
    const value = inputRef.current!.value;
    const foundCustomer = customers.find(customer => customer.corp_nm === value);

    if (foundCustomer) {
      setSelectedCustomer(foundCustomer);
    }
  }

  function handleClose() {
    props.setShow(false);
    initialize();
  }

  function handleModifyCustomer() {
    const currentIndex = isAdded ? editingCellRow! - data.length : editingCellRow!;

    const func = isAdded ? setAddedData : setData;
    const curData = isAdded ? addedData : data;

    if (selectedCustomer) {
      func(curData.map((data, i) => {
        if (i === currentIndex) {
          data.cus_no = selectedCustomer.cus_no;
          data.corp_nm = selectedCustomer.corp_nm;
        }

        return data;
      }));
      if (!isAdded) {
        onModify(currentIndex);
      }
      handleClose();
    }
  }

  useEffect(() => {
    if (props.show) {
      client
        .get('/api/master/customer', { params: { query: '' }})
        .then(res => {
          setCustomers(res.data);
          if (isAdded) {
            setSelectedCustomer(null);
          } else {
            const currentCustomer = (res.data as Customer[]).find(customer => customer.corp_nm === data[editingCellRow!].corp_nm);
            if (currentCustomer) {
              setSelectedCustomer(currentCustomer);
            }
          }
        })
    }
  }, [props.show]);

  return (
    <Modal show={props.show} centered>
      <Modal.Header>
        거래처 적용
      </Modal.Header>
      <Modal.Body>
        <Column>
          <ColumnLeft>
            거래처 선택하기
          </ColumnLeft>
          <ColumnRight>
            <input
              className='form-control'
              ref={inputRef}
              placeholder='검색어 입력'
              list='customerSelection'
              onInput={handleSelectCustomer}
            />
            <datalist id='customerSelection'>
              {customers.map((customer, i) =>
                <option key={i} value={customer.corp_nm}>{customer.corp_nm}</option>
              )}
            </datalist>
          </ColumnRight>
        </Column>
        <p className='text-secondary'>선택된 거래처: {selectedCustomer ? selectedCustomer.corp_nm : ''}</p>
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton onClick={handleClose}>
          닫기
        </SecondaryButton>
        <PrimaryButton onClick={handleModifyCustomer}>
          적용
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  )
}