import {ComponentPropsWithoutRef} from "react";

interface PrimaryButtonProps extends ComponentPropsWithoutRef<'button'> {}

export default function PrimaryButton(props: PrimaryButtonProps) {
  return (
    <button {...props} className='btn btn-primary'>
      {props.children}
    </button>
  )
}