interface Column {
  name: string;
  width: string;
}

export type TableColumn = string | Column;

interface TableHeadProps {
  columns: TableColumn[]
}

export default function TableHead({ columns }: TableHeadProps) {
  return (
    <thead>
    <tr>
      {columns.map((column, i) => (
        <td key={i} style={{
          fontWeight: 'bold',
          textAlign: 'center',
          width: typeof column === 'string' ? undefined : column.width
        }}>
          {typeof column === 'string' ? column : column.name}
        </td>
      ))}
    </tr>
    </thead>
  )
}