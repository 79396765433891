import Container from "./atom/Container";
import {useCallback, useState} from "react";
import MasterHeader from "./molecule/MasterHeader";
import AccountsDisplay from "./organism/AccountsDisplay";
import CustomersDisplay from "./organism/CustomersDisplay";

export default function Master() {

  const [radioValue, setRadioValue] = useState<string>('accounts');

  const displayedTable = useCallback(() => {
    switch (radioValue) {
      case 'accounts':
        return <AccountsDisplay />;

      case 'customers':
        return <CustomersDisplay />;

      default:
        return null;
    }
  }, [radioValue])

  return (
    <Container>
      <MasterHeader radioValue={radioValue} setRadioValue={setRadioValue} />
      {displayedTable()}
    </Container>
  )
}