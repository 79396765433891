import React, {useEffect, useMemo, useRef, useState} from 'react'
import client from '../../../../axios'
import {ICusSearchData, ICustomer} from '../../../interfaces/standard-info/i-customer'
import {IColumn, INameValue} from "../../../interfaces/common";
import CustomerList from "./customer-list";
import InfoModal from "../../../common-modals/info-modal";
import AddModal from './modals/add-modal'
import * as buttonActions from '../../../../redux/actions/button'
import './styles.css'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";
import {search} from "../../../../redux/actions/button";

const defaultSearchData: ICusSearchData = {
	cus_nm: '',
	addr: '',
	tel: '',
	use_gbn: '1'
}

/*
* 사용 구분
* 전체: 보이는 것->0, 실제 값->2
* 사용: 보이는 것->1, 실제 값->1
* 미사용: 보이는 것->2, 실제 값->0
* */

const Customer = () => {

	/* 검색창의 레퍼런스 */
	const cusNmRef = useRef<HTMLInputElement>(null)
	const addrRef = useRef<HTMLInputElement>(null)
	const telRef = useRef<HTMLInputElement>(null)
	const useGbnRef = useRef<HTMLSelectElement>(null)

	/* 테이블 헤더 속성 */
	const customerTableHeaders = useMemo(() => [
		{ name: '코드', style: { minWidth: '50px', width: '60px', textAlign: 'center' } },
		{ name: '거래처명', style: { minWidth: '180px', width: '180px', textAlign: 'center' } },
		{ name: '관리용거래처명', style: { minWidth: '180px', width: '180px', textAlign: 'center' } },
		{ name: '사업자등록번호', style: { minWidth: '110px', width: '110px', textAlign: 'center'  } },
		{ name: '대표자명', style: { minWidth: '80px', width: '80px', textAlign: 'center' } },
		{ name: '업태', style: { minWidth: '110px', width: '110px', textAlign: 'center' } },
		{ name: '종목', style: { minWidth: '110px', width: '110px', textAlign: 'center' } },
		{ name: '전화', style: { minWidth: '120px', width: '120px', textAlign: 'center' } },
		{ name: '팩스', style: { minWidth: '120px', width: '120px', textAlign: 'center' } },
		{ name: '우편번호', style: { minWidth: '70px', width: '70px', textAlign: 'center' } },
		{ name: '주소', style: { minWidth: '500px', width: '1000px', textAlign: 'center' } },
		{ name: '홈택스이메일', style: { minWidth: '200px', width: '500px', textAlign: 'center' } },
		{ name: '결제계좌정보', style: { minWidth: '300px', width: '500px', textAlign: 'center' } },
		{ name: '비고', style: { minWidth: '300px', width: '500px', textAlign: 'center' } },
		{ name: '사용여부', style: { minWidth: '70px', width: '70px', textAlign: 'center' }}
	] as IColumn[], [])

	/* 사용여부 구분 */
	const useCategory = [
		{ name: '전체', value: '2' },
		{ name: '사용', value: '1' },
		{ name: '미사용', value: '0' }
	] as INameValue[]

	/* 데이터베이스에서 불러오는 행의 목록 / 특정 행 사용할 때 */
	const [ customerList, setCustomerList ] = useState<ICustomer[]>([])
	/* 거래처, 주소, 전화 찾을 때 사용하는 state, 객체로 통합해서 관리 */
	const [ searchData, setSearchData ] = useState<ICusSearchData>(defaultSearchData)

	/* 거래처 추가 모달 띄우기 */
	const [ showAddModal, setShowAddModal ] = useState(false)

	/* 수행 실패 시 뜰 모달 */
	const [ modalFailed, setModalFailed ] = useState(false)
	/* 알림 실패에 뜰 메시지 */
	const [ failedMessage, setFailedMessage ] = useState('')

	/* redux에서 버튼 액션 불러오기 */
	const btnActions = useSelector((state: RootReducer) => state.buttonActions, shallowEqual)
	const dispatch = useDispatch()

	/* 초기에 불러올 시 데이터 fetch */
	useEffect(() => {
		cusNmRef.current?.focus()
		fetchCusData(searchData.cus_nm, searchData.addr, searchData.tel, searchData.use_gbn)
	}, [])

	const fetchCusData = (cus_nm: string, addr: string, tel: string, use_gbn: string) => {
		client.post('/api/standard-info/get-customer-data', {
			payload: { cus_nm, addr, tel, use_gbn }
		})
			.then(res => {
				if(res.data.result === 'expired') {
					setModalFailed(true)
					setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
				} else if (res.data.result === 'failed') {
					setModalFailed(true)
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const data = res.data.payload.rowsCustomer as ICustomer[]
					setCustomerList(data.map((element: ICustomer) => ({
						...element,
						saup_no: element.saup_no || '',
						owner: element.owner || '',
						up: element.up || '',
						jong: element.jong || '',
						tel: element.tel || '',
						fax: element.fax || '',
						zip: element.zip || '',
						addr: element.addr || '',
						tax_email: element.tax_email || '',
						bank_owner: element.bank_owner || '',
						bigo: element.bigo || '',
					})))
				}
			})
			.catch(() => {
				setModalFailed(true)
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}

	useEffect(() => {
		if(btnActions.active) {
			if(btnActions.action === 'input') {
				setShowAddModal(true)
			} else if (btnActions.action === 'search') {
				fetchCusData(searchData.cus_nm, searchData.addr, searchData.tel, searchData.use_gbn)
			}
		}
		dispatch(buttonActions.clear())
	}, [btnActions.active, btnActions.action, dispatch])

	const handleUpdate = async (updatedRow: ICustomer) => {
		/* 하위 컴포넌트 중 ProductRow 에서 받아온 값을 토대로 수정 수행 */
		try {
			const response = await client.post('/api/standard-info/update-customer-data', { row: updatedRow })
			if(response.data.result === 'expired') {
				setModalFailed(true)
				setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
			} else if (response.data.result === 'failed') {
				setModalFailed(true)
				setFailedMessage('서버에 문제가 발생하였습니다.')
			} else {
				setCustomerList(customerList.map(customer =>
					(customer.cus === updatedRow.cus) ?
						updatedRow :
						customer
				))
			}
		} catch (e: any) {
			setModalFailed(true)
			setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
		}
	}

	const handleAdd = async (newRow: ICustomer) => {
		let response, customerCode

		try {
			response = await client.get('/api/standard-info/get-new-customer-code')
			customerCode = response.data.newCustomerCode.seq;

			if(response.data.result === 'expired') {
				setModalFailed(true)
				setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
			} else if (response.data.result === 'failed') {
				setModalFailed(true)
				setFailedMessage('서버에 문제가 발생하였습니다.')
			} else {
				try {
					const row = {
						...newRow,
						cus: '00000'.slice(0, '00000'.length - customerCode.length) + customerCode,
						cus_usr: '00000'.slice(0, '00000'.length - customerCode.length) + customerCode
					}
					response = await client.post('/api/standard-info/add-customer-data', { row })
					if(response.data.result === 'expired') {
						setModalFailed(true)
						setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
					} else if (response.data.result === 'failed') {
						setModalFailed(true)
						setFailedMessage('서버에 문제가 발생하였습니다.')
					} else {
						setCustomerList([ ...customerList, row ])
						setShowAddModal(false)
					}
				} catch (e) {
					setModalFailed(true)
					setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
				}
			}
		} catch (e: any) {
			setFailedMessage('통신에 에러가 발생하였습니다.')
			setModalFailed(true)
		}
	}

	/* 검색란 enter */
	const handleEnterOnCusNm = (e: any) => {
		if(e.key === 'Enter') {
			addrRef.current?.focus()
		}
	}
	const handleEnterOnAddr = (e: any) => {
		if(e.key === 'Enter') {
			telRef.current?.focus()
		}
	}
	const handleEnterOnTel = (e: any) => {
		if(e.key === 'Enter') {
			useGbnRef.current?.focus()
		}
	}

	return (
		<>
			{/* 위쪽 메뉴 */}
			<div className="d-flex mt-2 me-4">
				<div className="flex-grow-1 d-flex ms-3 align-items-center">

					{/* 거래처 */}
					<div className="d-flex align-content-center mx-2">
						<label className="col-auto align-items-center my-1 mx-1">거래처</label>
						<div className="input-group input-group-sm">
							<input ref={cusNmRef} type="text" className="form-control" value={searchData.cus_nm}
								   onChange={e => setSearchData({ ...searchData, cus_nm: e.target.value })} onKeyDown={handleEnterOnCusNm}/>
						</div>
					</div>

					{/* 주소 */}
					<div className="d-flex align-content-center mx-2">
						<label className="col-auto align-items-center my-1 mx-1">주소</label>
						<div  className="input-group input-group-sm">
							<input ref={addrRef} type="text" className="form-control" value={searchData.addr}
								   onChange={e => setSearchData({ ...searchData, addr: e.target.value })} onKeyDown={handleEnterOnAddr}/>
						</div>
					</div>

					{/* 전화 */}
					<div className="d-flex align-content-center mx-2">
						<label className="col-auto align-items-center my-1 mx-1">전화</label>
						<div className="input-group input-group-sm">
							<input ref={telRef} type="text" className="form-control" value={searchData.tel} onKeyDown={handleEnterOnTel}
								   onChange={e => setSearchData({ ...searchData, tel: e.target.value })} />
						</div>
					</div>

					{/* 사용여부 */}
					<div className="d-flex align-content-center mx-2">
						<label className="col-auto align-items-center my-1 mx-1">사용여부</label>
						{/* 옵션이 바뀌면 자동으로 표시 리스트 갱신 */}
						<select ref={useGbnRef} defaultValue="1" className="form-select form-select-sm"
								onChange={e => setSearchData({ ...searchData, use_gbn: e.target.value })}>
							{useCategory.map((category, index) =>
								<option key={index} value={category.value}>{`${index}. ${category.name}`}</option>
							)}
						</select>
					</div>
				</div>
			</div>

			<div className="my-3 mx-3 scrollbar table-wrapper">
				<table className="table table-sm table-bordered table-hover table-condensed">
					<thead className="sticky-head">
					<tr>
						{customerTableHeaders.map((value, index) =>
							<th style={value.style} key={`cshead-${index + 1}`} scope="col">{value.name}</th>
						)}
					</tr>
					</thead>

					<tbody>
					<CustomerList data={customerList}
								  useCategory={useCategory}
								  onUpdate={handleUpdate}/>
					</tbody>
				</table>
			</div>

			<InfoModal show={modalFailed} onHide={() => setModalFailed(false)} title={'에러'}
					   message={failedMessage} onButtonClick={() => setModalFailed(false)}/>
			<AddModal show={showAddModal} onHide={() => setShowAddModal(false)} onAdd={handleAdd} useCategory={useCategory} />
		</>
	)
}

export default Customer