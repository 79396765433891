import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, Outlet, useNavigate} from 'react-router-dom'

import client from '../../axios'
import {RootReducer} from '../../redux/reducers'
import * as currentPageActions from '../../redux/actions/current-page'
import * as btnActions from '../../redux/actions/button'
import * as timerActions from '../../redux/actions/timer'
import * as changeMenuActions from '../../redux/actions/change-menu'
import InfoModal from '../common-modals/info-modal'
import '../../index.css'
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import Timer from "../../redux/reducers/timer";

const dashboardMode = {
  search: false,
  input: false,
  save: false,
  remove: false,
  printContents: false,
  excel: false
}

const Navbar = () => {


  const refs = [
    useRef<HTMLButtonElement>(null),
    useRef<HTMLButtonElement>(null),
    useRef<HTMLButtonElement>(null),
    useRef<HTMLButtonElement>(null),
    useRef<HTMLButtonElement>(null)
  ]

  /* 남은 시간 */
  // const {seconds} = useSelector((state: RootReducer) => state.timer, shallowEqual)
  const [seconds, setSeconds] = useState(1800)
  const timeoutRef = useRef<NodeJS.Timer | null>(null);
  /* currentPage 불러옴 */
  const currentPageElem = useSelector((state: RootReducer) => state.currentPage, shallowEqual)
  /* 유저/회사명 담음 */
  const [userProfile, setUserProfile] = useState({user_nm: '', corp_nm: ''})
  /* 현재 화면 이름 */
  const [currentPage, setCurrentPage] = useState('대시보드')
  /* 네비게이트 사용 */
  const navigate = useNavigate()
  /* 버튼 사용 가능 여부 */
  const [btnAvailable, setBtnAvailable] = useState(dashboardMode)
  /* 에러 메시지와 여부 */
  const [errorMsg, setErrorMsg] = useState('')
  const [showErrorMsg, setShowErrorMsg] = useState(false)

  /* 버튼 내용 */
  const btnContents = [
    {
      content: '조회', handleClick: () => {
        dispatch(btnActions.search(true))
      }, available: btnAvailable.search, style: 'secondary'
    },
    {
      content: '입력', handleClick: () => {
        dispatch(btnActions.input(true))
      }, available: btnAvailable.input, style: 'secondary'
    },
    {
      content: '저장', handleClick: () => {
        dispatch(btnActions.save(true))
      }, available: btnAvailable.save, style: 'secondary'
    },
    {
      content: '삭제', handleClick: () => {
        dispatch(btnActions.remove(true))
      }, available: btnAvailable.remove, style: 'secondary'
    },
    {
      content: '인쇄', handleClick: () => {
        dispatch(btnActions.printContents(true))
      }, available: btnAvailable.printContents, style: 'secondary'
    },
  ]

  // const btnIcons = [
  //   <i className="bi bi-search me-2"/>,
  //   <i className="bi bi-arrow-down-circle-fill me-2"/>,
  //   <i className="bi bi-cloud-arrow-up-fill me-2"/>,
  //   <i className="bi bi-trash-fill me-2"/>,
  //   <i className="bi bi-printer-fill me-2"/>,
  // ]

  const tooltipContents = [
    'Ctrl + Q',
    'Ctrl + I',
    'Ctrl + S',
    'Ctrl + D',
    'Ctrl + P'
  ]

  /* 대시보드로 돌아가기 위해 currentPage.actions를 dispatch */
  const dispatch = useDispatch()

  useEffect(() => {

    switch (currentPageElem.current_page) {
      case 'dashboard':
        setCurrentPage('공지사항')
        setBtnAvailable(dashboardMode)
        break

      /* 입출고관리 */

      case 'input-buy':
        setCurrentPage('입고(구매) 입력')
        setBtnAvailable({...dashboardMode, remove: true, search: true, input: true})
        break

      case 'input-sell':
        setCurrentPage('출고(판매) 입력')
        setBtnAvailable({...dashboardMode, remove: true, search: true, input: true})
        break

      /* 생산관리 */

      case 'add-recipe':
        setCurrentPage('레시피 등록')
        setBtnAvailable({...dashboardMode, remove: true, search: true, input: true})
        break

      case 'prod-perf':
        setCurrentPage('생산실적 입력')
        setBtnAvailable({...dashboardMode, remove: true, input: true, search: true})
        break

      case 'prod-daily':
        setCurrentPage('생산일보')
        setBtnAvailable({...dashboardMode, search: true, save: true, printContents: true})
        break

      /* 재고관리리 */

      case 'stock-pay':
        setCurrentPage('재고수불부')
        setBtnAvailable({...dashboardMode, search: true})
        break

      case 'stock-status':
        setCurrentPage('재고현황')
        setBtnAvailable({...dashboardMode, search: true})
        break

      case 'initial-stock':
        setCurrentPage('기초재고 입력')
        setBtnAvailable({...dashboardMode, remove: true, save: true})
        break

      case 'mat-pay':
        setCurrentPage('원료수불부')
        setBtnAvailable({...dashboardMode, search: true, save: true, printContents: true})
        break


      /* 기준정보 */
      case 'user-com':
        setCurrentPage('사용회사 등록')
        setBtnAvailable({...dashboardMode, save: true})
        break

      case 'standard-code':
        setCurrentPage('기준코드 등록')
        setBtnAvailable({...dashboardMode, save: true, input: true})
        break

      case 'customer':
        setCurrentPage('거래처 등록')
        setBtnAvailable({...dashboardMode, input: true, search: true})
        break

      case 'product':
        setCurrentPage('품목 등록')
        setBtnAvailable({...dashboardMode, input: true, search: true, remove: true})
        break

      case 'settings':
        setCurrentPage('환경설정')
        setBtnAvailable(dashboardMode)
        break

      default:
        setCurrentPage('공지사항')
        setBtnAvailable(dashboardMode)
    }
  }, [currentPageElem.current_page])


  /* 렌더링 되면 도큐먼트에 이벤트 리스너 넣기, 로그아웃하면 못 쓰게 만들음 */
  useEffect(() => {
    const handleKeyEvent = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'q') {
        refs[0].current?.click()
      } else if (e.ctrlKey && e.key === 'i') {
        refs[1].current?.click()
      } else if (e.ctrlKey && e.key === 's') {
        e.preventDefault()
        refs[2].current?.click()
      } else if (e.ctrlKey && e.key === 'd') {
        e.preventDefault()
        refs[3].current?.click()
      } else if (e.ctrlKey && e.key === 'p') {
        e.preventDefault()
        refs[4].current?.click()
      }
    }

    document.addEventListener('keydown', handleKeyEvent)

    return () => document.removeEventListener('keydown', handleKeyEvent)
  }, [])

  /* timeout 설정 */
  useEffect(() => {
    const resetTimer = () => setSeconds(1800);

    timeoutRef.current = setInterval(() => {
      if (seconds === 0) {
        clearTimeout(timeoutRef.current!);
        navigate('/login');
      } else {
        setSeconds(prev => prev - 1);
      }
    }, 1000);

    document.addEventListener('extend_login', resetTimer);

    return () => {
      clearTimeout(timeoutRef.current!);
      document.removeEventListener('extend_login', resetTimer);
    };
  }, [navigate, seconds, dispatch])

  /* 유저 정보(유저명, 회사명) 가져오기 */
  useEffect(() => {
    client.get('/api/auth/get-user-profile')
      .then(res => {
        const data = res.data
        if (data.result === 'success') {
          setUserProfile({
            user_nm: data.user_nm,
            corp_nm: data.corp_nm,
          })
          dispatch(currentPageActions.setAdminGbn(data.admin_gbn))
        } else if (data.result === 'expired') {
          setErrorMsg('세션이 만료되었습니다. 다시 로그인하세요.')
          setShowErrorMsg(true)
        } else {
          setErrorMsg('서버에 문제가 발생하였습니다.')
          setShowErrorMsg(true)
        }
      })
      .catch(() => {
        setErrorMsg('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
        setShowErrorMsg(true)
      })
  }, [])

  /* 로그인 연장 */
  const handleExtend = async () => {
    try {
      const response = await client.get('/api/auth/extend-login')
      if (response.data.result === 'success') {
        setSeconds(1800);
      } else {
        if (response.data.result === 'expired') {
          navigate('/login')
        } else {
          setErrorMsg('서버에 문제가 발생하였습니다.')
          setShowErrorMsg(true)
        }
      }

    } catch (e: any) {
      setErrorMsg('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
      setShowErrorMsg(true)
    }
  }

  /* 로그아웃 다루기 */
  const handleLogout = async () => {
    dispatch(changeMenuActions.reset())
    dispatch(currentPageActions.setAdminGbn('1'))
    navigate('/login')
  }

  const handleShortcut = (e: any) => {
    if (e.key === 'Enter') {
      console.log('wow')
    }
  }

  return (
    <div className="sticky-top">
      <header className='navbar navbar-expand-lg navbar-light col-12 border-bottom bg-light'
              style={{backgroundColor: '#e0e0e0'}}>
        <div className="d-flex flex-column flex-sm-row flex-grow-1">
          <div className="d-flex px-3 justify-content-center" style={{width: '250px'}}>
            <button className="bg-transparent border-0"
                    onClick={() => {
                      dispatch(currentPageActions.dashboard())
                      dispatch(btnActions.clear())
                    }}>
              <img src="img/checkinglogo.svg" alt="" width="160px"/>
            </button>
          </div>

          <div className="d-none d-sm-flex justify-content-center justify-content-sm-start">
            <label className="text-dark my-auto">{userProfile.corp_nm}({userProfile.user_nm})님, 환영합니다.</label>
          </div>
          <div className="mx-sm-5 d-flex flex-grow-1 justify-content-lg-start justify-content-center"
               onKeyDown={handleShortcut}>
            {btnContents.map((contents, index) =>
              <OverlayTrigger key={`overlay-${index}`}
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-${index}`}>
                                  {tooltipContents[index]}
                                </Tooltip>
                              }
              >
                <Button ref={refs[index]} variant={`${contents.style}`} className="my-2 mx-1"
                        onClick={contents.handleClick} hidden={!contents.available}>
                  {contents.content}
                </Button>
              </OverlayTrigger>
            )}
          </div>
          <div className="my-2 mx-5 d-none d-sm-flex">
            <Button variant="outline-primary" className="me-3" onClick={() => {
              window.open(`/assets/manual.pdf`)
            }}>
              사용설명서
            </Button>
            <a href="http://helpu.kr/esse01/" target="_blank">
              <Button variant="outline-success" className="me-3">
                원격상담
              </Button>
            </a>
          </div>
        </div>
      </header>

      <header
        className="navbar navbar-expand-lg navbar-light d-none d-sm-block col-12 bg-light justify-content-center justify-content-sm-start p-0 border-bottom">
        <div className="d-flex flex-column flex-sm-row">
          <div className="d-flex justify-content-center p-2 navbar-under-left">
            <label
              className="my-1 mx-1">{`${('0' + Math.floor(seconds / 60)).slice(-2)}:${('0' + seconds % 60).slice(-2)}`}</label>
            <button className="btn btn-secondary btn-sm mx-1 logout-btn" onClick={handleExtend}>로그인연장</button>
            <button className="btn btn-secondary btn-sm mx-1 logout-btn" onClick={handleLogout}>로그아웃</button>
          </div>
          <div className="d-flex align-content-center justify-content-sm-start justify-content-center flex-grow-1">
            <h5 className="d-flex ps-2 my-auto m-0 col-auto">
              {currentPage}
            </h5>
          </div>
        </div>
      </header>

      <InfoModal show={showErrorMsg} onHide={() => setShowErrorMsg(false)} title={'에러'}
                 message={errorMsg} onButtonClick={() => setShowErrorMsg(false)}/>
      {/* Outlet이 있어야 라우팅이 변경되는 동안에도 navbar가 유지될 수 있다. */}
      <Outlet/>
    </div>
  )
}

export default Navbar