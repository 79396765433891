import {ReactNode} from "react";

interface TableProps {
  children?: ReactNode;
  width?: number;
}

export default function Table({ children, width }: TableProps) {
  return (
    <table
      className='table table-sm table-hover table-bordered table-condensed my-2'
      style={{ width }}
    >
      {children}
    </table>
  )
}