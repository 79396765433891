import {ComponentPropsWithoutRef} from "react";

interface DangerButtonProps extends ComponentPropsWithoutRef<'button'> {}

export default function DangerButton(props: DangerButtonProps) {
  return (
    <button {...props} className='btn btn-danger'>
      {props.children}
    </button>
  )
}