import React, {useCallback, useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import Sidebar from './sidebar/sidebar'

/* 메인 기능들 */
// import AnnouncementComp from './main/announcement/announcement-comp'
import UserCompanyInfo from './main/standard-info/user-company-info/user-company-info'
import StandardCode from './main/standard-info/standard-code/standard-code'
import Customer from './main/standard-info/customer/customer'
import Product from './main/standard-info/product/product'
import StockStatus from './main/stock-manage/stock-status/stock-status'
import InitialStock from './main/stock-manage/initial-stock/initial-stock'
import GoodsBuySell from './main/in-out-manage/goods-buy-sell/goods-buying'
import AddRecipe from "./main/production-manage/add-recipe/add-recipe";
import ProductionPerformance from "./main/production-manage/input-prod-perf/production-performance";
import StockPay from "./main/stock-manage/stock-pay/stock-pay";
import ProdDaily from "./main/production-manage/prod-daily/prod-daily";

import {RootReducer} from '../redux/reducers'
import client from '../axios'
import * as timerActions from '../redux/actions/timer'
import {useNavigate} from 'react-router-dom'
import InfoModal from './common-modals/info-modal'

import MatPay from "./main/stock-manage/mat-pay/mat-pay";
import * as changeMenuActions from '../redux/actions/change-menu'
import * as currentPageActions from '../redux/actions/current-page'
import * as decActions from '../redux/actions/dec'

import 'bootstrap-icons/font/bootstrap-icons.css'

import {Modal} from "react-bootstrap";
import Settings from "./main/settings/settings";

const Index = () => {

  /* 현재 페이지 */
  const {current_page} = useSelector((state: RootReducer) => state.currentPage, shallowEqual)
  const {changed} = useSelector((state: RootReducer) => state.changeMenu, shallowEqual)
  const [errorMsg, setErrorMsg] = useState('')
  const [showErrorMsg, setShowErrorMsg] = useState(false)
  const [currentComponent, setCurrentComponent] = useState('dashboard')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  /* 컴포넌트 변경 시 띄울 모달 */
  const [showPageChangeModal, setShowPageChangeModal] = useState(false)

  /* store의 currentPage state에 따라 보이는 컴포넌트 수정 */
  const ShownComponent = useCallback(() => {
    switch (currentComponent) {
      // case 'dashboard':
      //     return <AnnouncementComp />

      case 'input-buy':
        return <GoodsBuySell buySellMode={1}/>
      case 'input-sell':
        return <GoodsBuySell buySellMode={2}/>

      case 'add-recipe':
        return <AddRecipe/>
      case 'prod-perf':
        return <ProductionPerformance/>
      case 'stock-pay':
        return <StockPay/>
      case 'mat-pay':
        return <MatPay/>

      case 'stock-status':
        return <StockStatus/>
      case 'initial-stock':
        return <InitialStock/>
      case 'prod-daily':
        return <ProdDaily/>

      case 'user-com':
        return <UserCompanyInfo/>
      case 'standard-code':
        return <StandardCode/>
      case 'customer':
        return <Customer/>
      case 'product':
        return <Product/>

      case 'settings':
        return <Settings/>

      // default:
      //     return <AnnouncementComp />
      default:
        return <></>
    }
  }, [currentComponent])

  useEffect(() => {
    client.get('/api/settings/get-dec')
      .then(res => dispatch(decActions.setDec(res.data.payload.dec)))
      .catch(() => {
        setErrorMsg('데이터 불러오기에 실패했습니다.')
        setShowErrorMsg(true)
      })
  }, [])

  useEffect(() => {
    client.get('/api/auth/extend-login')
      .then(res => {
        if (res.data.result !== 'success') {
          navigate('/login')
        } else {
          const ev = new CustomEvent("extend_login");
          document.dispatchEvent(ev);
        }
      })
      .catch(() => {
        setErrorMsg('로그인 연장에 에러가 발생하였습니다.')
        setShowErrorMsg(true)
      })
  }, [navigate, current_page, dispatch])

  useEffect(() => {
    if (changed) {
      setShowPageChangeModal(true)
    } else {
      setCurrentComponent(current_page)
    }
  }, [current_page])

  /* 페이지 변경 그만두기 */
  const handleCancelPageChange = () => {
    setShowPageChangeModal(false)
    dispatch(changeMenuActions.reset())
    dispatch(currentPageActions.resetChange())
  }

  const handlePageChange = () => {
    setCurrentComponent(current_page)
    setShowPageChangeModal(false)
    dispatch(changeMenuActions.reset())
  }

  return (
    <>
      <div className="container-fluid d-flex">
        <div className="row vw-100">
          <Sidebar/>
          <main className="py-2 main">
            <ShownComponent/>
          </main>
        </div>
      </div>

      <InfoModal show={showErrorMsg} onHide={() => setShowErrorMsg(false)} title={'오류'} message={errorMsg}
                 onButtonClick={() => setShowErrorMsg(false)}/>

      <Modal show={showPageChangeModal} centered>
        <Modal.Body>
          나가시면 변경 내용이 저장되지 않습니다.<br/>그래도 나가시겠습니까?
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-secondary my-2" onClick={handleCancelPageChange}>취소</button>
          <button className="btn btn-primary my-2" onClick={handlePageChange}>나가기</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Index