import Flex from "../../../components/atom/Flex";

interface RadioGroupProps {
  radioValue: string;
  setRadioValue: (value: string) => void;
}

export default function RadioGroup({radioValue, setRadioValue}: RadioGroupProps) {
  return (
    <Flex className='gap-2 my-auto'>
      <div className="form-check">
        <input
          id="accounts"
          type="radio"
          className='form-check-input'
          checked={radioValue === 'accounts'}
          value={"accounts"}
          onChange={e => setRadioValue(e.target.value)}
        />
        <label htmlFor="accounts">
          계정 관리
        </label>
      </div>
      <div className='form-check'>
        <input
          id="customers"
          type="radio"
          className='form-check-input'
          checked={radioValue === 'customers'}
          value={"customers"}
          onChange={e => setRadioValue(e.target.value)}
        />
        <label htmlFor="customers">
          거래처 관리
        </label>
      </div>
    </Flex>
  )
}