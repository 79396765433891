import {useEffect, useMemo, useRef, useState} from "react";
import CenterCell from "../atom/table/CenterCell";
import AdminGbnSelection from "../atom/selections/AdminGbnSelection";
import OnGbnSelection from "../atom/selections/OnGbnSelection";
import EditableCell from "../atom/EditableCell";
import client from "../../../axios";
import {Account} from "../interfaces/Account";
import FormControl from "../../../components/atom/FormControl";
import ModifyAccountModal from "../modals/accounts/ModifyAccountModal";

interface AccountsTableBody {
  data: Account[];
  setData: (data: Account[]) => void;
  addedData: Account[];
  setAddedData: (data: Account[]) => void;
  searchData: string;
  onModify: (newIndex: number) => void;
}

export default function AccountsTableBody(
  { data, setData, addedData, setAddedData, searchData, onModify }: AccountsTableBody
) {
  const [editingCellRow, setEditingCellRow] = useState<number | null>(null);
  const [modifyingCellRow, setModifyingCellRow] = useState<number | null>(null);
  const tableRef = useRef<HTMLTableSectionElement | null>(null);
  const tableData = useMemo(() => data.concat(addedData), [data, addedData]);
  const [focusMode, setFocusMode] = useState<"id" | "pwd" | "user_nm" | null>(null);

  const [openModifyAccount, setOpenModifyAccount] = useState(false);

  function handleCellClick(rowIndex: number, mode: "id" | "pwd" | "user_nm") {
    setEditingCellRow(rowIndex);
    setFocusMode(mode);
  }

  function handleChange(key: keyof Account, value: string, i: number) {
    const isAddedData = tableData[i].usr_no.length === 0;
    const tmpData = Array.from(isAddedData ? addedData : data);
    const ipp = isAddedData ? (i - data.length) : i;

    tmpData[ipp][key] = value;

    if (isAddedData) {
      setAddedData(tmpData);
    } else {
      setData(tmpData);
      onModify(ipp);
    }
  }

  function handleBlur() {
    setEditingCellRow(null);
    setFocusMode(null);
  }

  function handleClickOutside(e: MouseEvent) {
    if (tableRef.current && !tableRef.current.contains(e.target as Node)) {
      handleBlur();
    }
  }

  function isEditing(index: number, mode: "id" | "pwd" | "user_nm") {
    return index === editingCellRow && mode === focusMode;
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    client
      .get('/api/master/account', { params: { query: searchData } })
      .then((res) => setData(res.data))
  }, [searchData]);

  return (
    <>
      <tbody ref={tableRef}>
      {tableData.map((account, i) =>
        <tr key={i} className='align-content-center'>
          <CenterCell>
            {account.usr_no}
          </CenterCell>
          <CenterCell>
            <p
              className={`m-0 text-decoration-underline ${account.cus_no.length === 0 ? 'text-secondary' : ''}`}
              onClick={() => {
                setModifyingCellRow(i);
                setOpenModifyAccount(true);
              }}
            >
              {account.cus_no.length === 0 ? '클릭' : account.corp_nm}
            </p>
          </CenterCell>
          <td>
            {
              i < data.length ?
                account.id :
                <EditableCell
                  value={account.id}
                  isEditing={isEditing(i, "id")}
                  onClick={() => handleCellClick(i, "id")}
                  onChange={e => handleChange("id", e.target.value, i)}
                  defaultMessage="ID 입력"
                />
            }
          </td>
          <td>
            <EditableCell
              value={account.pwd}
              isEditing={isEditing(i, "pwd")}
              onClick={() => handleCellClick(i, "pwd")}
              onChange={e => handleChange("pwd", e.target.value, i)}
            />
          </td>
          <td>
            <EditableCell
              value={account.user_nm}
              isEditing={isEditing(i, "user_nm")}
              onClick={() => handleCellClick(i, "user_nm")}
              onChange={e => handleChange("user_nm", e.target.value, i)}
              defaultMessage="사용자명 입력"
            />
          </td>
          <td>
            <AdminGbnSelection
              value={account.admin_gbn}
              onChange={value => handleChange("admin_gbn", value, i)}
            />
          </td>
          <td>
            <OnGbnSelection
              value={account.on_gbn}
              onChange={value => handleChange("on_gbn", value, i)}
            />
          </td>
        </tr>
      )}
      </tbody>
      <ModifyAccountModal
        editingCellRow={modifyingCellRow}
        show={openModifyAccount}
        setShow={setOpenModifyAccount}
        data={data}
        setData={setData}
        addedData={addedData}
        setAddedData={setAddedData}
        onModify={onModify}
      />
    </>
  )
}