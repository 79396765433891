import React, {useEffect, useMemo, useRef, useState} from 'react'
import {getDate} from "../../../../lib/util-func";
import {IColumn} from "../../../interfaces/common";
import {
	IBeforeProdPerf,
	IClickedProdPerf,
	IProdPerf,
	ISelectedProdPerf
} from "../../../interfaces/production-manage/i-prod-perf";
import {IProductData} from "../../../interfaces/stock-manage/i-initial-stock";
import client from "../../../../axios";
import * as buttonActions from '../../../../redux/actions/button'
import {convertDateWithHyphen} from "../../../../lib/converting-func";
import SmlProductModal from "../../../common-modals/sml-product-modal";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootReducer} from "../../../../redux/reducers";
import InfoModal from "../../../common-modals/info-modal";
import {AddModModalContext, OriginalProdPerfContext, SelectedProdPerfContext} from "./production-performance-context";
import ProdPerfList from "./production-performance-list";
import AddModModal from "./add-mod-modal";
import {Modal} from "react-bootstrap";

export const defaultClickedProdPerf = {
	index: -1,
	ymd: '',
	item: '',
	code: ''
}

const ProductionPerformance = () => {
	const defaultSearchData = { ymd1: getDate(), ymd2: getDate(), item: '', item_usr: '', item_nm: '' }
	/* 품명 레퍼런스 */
	const itemNmRef = useRef<HTMLInputElement>(null)



	/* 생산실적 데이터, 수정/추가용 데이터 */
	const [ prodPerf, setProdPerf ] = useState<IProdPerf[]>([])
	const [ beforeProdPerf, setBeforeProdPerf ] = useState<IBeforeProdPerf[]>([])
	const [ selectedProdPerf, setSelectedProdPerf ] = useState<ISelectedProdPerf[]>([])
	const [ clickedProdPerf, setClickedProdPerf ] = useState<IClickedProdPerf>(defaultClickedProdPerf)
	/* 품목 데이터 */
	const [ productData, setProductData ] = useState<IProductData[]>([])
	/* sml product list 보이기 */
	const [ showSmlProductList, setShowSmlProductList ] = useState(false)
	/* 수행 실패 모달 변수 */
	const [ showFailedModal, setShowFailedModal ] = useState(false)
	const [ failedMessage, setFailedMessage ] = useState('')
	const [ failedTitle, setFailedTitle ] = useState('')
	/* 추가/변경 모달 데이터 */
	const [ addModMode, setAddModMode ] = useState(1)
	const [ showAddModModal, setShowAddModModal ] = useState(false)
	/* 검색 데이터 */
	const [ searchInputData, setSearchInputData ] = useState(defaultSearchData)
	const [ searchData, setSearchData ] = useState(defaultSearchData)
	/* 삭제 모달 띄우기 */
	const [ showDeleteModal, setShowDeleteModal ] = useState(false)
	const [ selectAll, setSelectAll ] = useState(false)

	/* 버튼 액션의 변화를 관찰 */
	const btnActions = useSelector((state: RootReducer) => state.buttonActions, shallowEqual)
	const dispatch = useDispatch()

	/* 칼럼 */
	const columns = useMemo(() => [
		{
			name: <input type="checkbox" className="form-check-input" checked={selectAll} onChange={() => setSelectAll(!selectAll)} />,
			style: { width: '40px', textAlign: 'center' }
		},
		{ name: '생산일', style: { width: '100px', minWidth: '90px', textAlign: 'center' } },
		{ name: '번호', style: { width: '50px', minWidth: '50px', textAlign: 'center' } },
		{ name: '코드', style: { width: '70px', minWidth: '60px', textAlign: 'center' } },
		{ name: '품명', style: { minWidth: '150px', width: '250px', textAlign: 'center' } },
		{ name: '규격', style: { minWidth: '150px', width: '250px', textAlign: 'center' } },
		{ name: '단위', style: { minWidth: '50px', width: '50px', textAlign: 'center' } },
		{ name: '생산량', style: { minWidth: '130px', width: '150px', textAlign: 'center' } },
		{ name: '계정구분', style: { minWidth: '80px', width: '80px', textAlign: 'center' } },
		{ name: '비고', style: { minWidth: '200px', width: '300px', textAlign: 'center' } },
		{ name: '', style: {} }
	] as IColumn[], [selectAll])

	/* 데이터 */
	const data = useMemo(() =>
		prodPerf
			.filter(element => {
				/* 검색란에 검색 */
				let ymd = false, item_nm = false
				let ymdNum = parseInt(element.ymd), ymd1Num = parseInt(searchData.ymd1), ymd2Num = parseInt(searchData.ymd2)

				if(element.item_nm.toLowerCase().includes(searchData.item_nm.toLowerCase()))
					item_nm = true

				if(ymdNum >= ymd1Num && ymdNum <= ymd2Num)
					ymd = true

				return ymd && item_nm
			}), [searchData, prodPerf])

	/* 생산실적, 제품 정보 불러옴 */
	const fetchProdPerf = () => {
		client.get('/api/production-manage/get-prod-perf')
			.then(res => {
				if(res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const { rowsProdPerf } = res.data.payload
					setProdPerf(rowsProdPerf.map((element: IProdPerf) => ({
						...element,
						qty: parseInt(element.qty).toString(),
						qty_bad: parseInt(element.qty_bad).toString(),
						qty_mix: parseInt(element.qty_mix).toString()
					})))
					setBeforeProdPerf(rowsProdPerf.map((element: IProdPerf) => (
						{
							index: element.index,
							ymd: element.ymd,
							item: element.item,
							code: element.code
						}
					)))
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}
	const fetchProductData = () => {
		client.get('/api/stock-manage/get-product-data')
			.then(res => {
				if(res.data.result === 'failed') {
					setShowFailedModal(true)
					setFailedTitle('데이터 가져오기 실패')
					setFailedMessage('서버에 문제가 발생하였습니다.')
				} else {
					const { rowsProduct } = res.data.payload
					setProductData(rowsProduct)
				}
			})
			.catch(() => {
				setShowFailedModal(true)
				setFailedTitle('데이터 가져오기 실패')
				setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			})
	}

	/* 날짜 변경 시 수행되는 이벤트 핸들러 */
	const handleChangeOnYMD = (e: React.ChangeEvent<HTMLInputElement>, mode: number) => {
		if(mode === 1) {
			setSearchInputData({ ...searchInputData, ymd1: e.target.value.replaceAll("-", "") })
		} else if(mode === 2)
			setSearchInputData({ ...searchInputData, ymd2: e.target.value.replaceAll("-", "") })
	}

	/* 아이템 필드 엔터 이벤트 */
	const handleEnterOnItemNm = (e: any) => {
		if(e.key === 'Enter') {
			const filtered = productData.filter(pd_elem => pd_elem.item_nm.toLowerCase().includes(e.target.value.toLowerCase()))
			const foundIndex = productData.findIndex(pd_elem => pd_elem.item_nm.toLowerCase().includes(e.target.value.toLowerCase()))
			const target = filtered[0]
			if(foundIndex === -1 || searchInputData.item_nm.length === 0 || filtered.length >= 2) {
				setShowSmlProductList(true)
			} else {
				setSearchInputData({ ...searchData, item: target.item, item_usr: target.item_usr, item_nm: target.item_nm })
				setSearchData({ ...searchData, item: target.item, item_usr: target.item_usr, item_nm: target.item_nm })
			}
		}
	}

	/* sml product data submit 핸들러 */
	const handleSubmitOnSmlProductSearch = (data: IProductData) => {
		setSearchInputData({ ...searchData, item: data.item, item_usr: data.item_usr, item_nm: data.item_nm })
		setShowSmlProductList(false)
	}

	/* 검색 버튼 클릭 */
	const handleClickOnSearchButton = () => {
		const value = itemNmRef.current === null ? '' : itemNmRef.current.value
		const foundIndex = productData.findIndex(pd_elem => pd_elem.item_nm.toLowerCase().includes(value.toLowerCase()))
		const filtered = productData.filter(pd_elem => pd_elem.item_nm.toLowerCase().includes(value.toLowerCase()))
		if(foundIndex === -1 || searchInputData.item_nm.length === 0 || filtered.length >= 2) {
			setShowSmlProductList(true)
		} else {
			setSearchInputData({ ...searchData, item_nm: filtered[0].item_nm })
		}
	}

	const handleDeleteData = async () => {
		setShowDeleteModal(false)
		try {
			const response = await client.post('/api/production-manage/delete-whole-prod-perf',
				{
					deletedRows: selectedProdPerf.map(element => ({
						code: element.code
					}))
				})
			if(response.data.result === 'expired') {
				setFailedMessage('세션이 만료되었습니다. 다시 로그인하세요.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else if(response.data.result === 'failed') {
				setFailedMessage('서버에 문제가 발생하였습니다.')
				setFailedTitle('저장 실패')
				setShowFailedModal(true)
			} else {
				setSelectedProdPerf([])
				setSelectAll(false)
				fetchProdPerf()
			}
		} catch (e: any) {
			setFailedMessage('통신에 에러가 발생했습니다. 인터넷 연결을 확인하세요.')
			setFailedTitle('통신 에러')
			setShowFailedModal(true)
		}
	}

	/* 컴포넌트 마운트 시 데이터 불러옴 */
	useEffect(() => {
		fetchProdPerf()
		fetchProductData()
	}, [])

	/* 버튼 클릭 시 취할 행동 */
	useEffect(() => {
		if(btnActions.active && btnActions.action === 'search') {
			if(parseInt(searchInputData.ymd1) > parseInt(searchInputData.ymd2)) {
				setShowFailedModal(true)
				setFailedTitle('잘못된 검색 데이터')
				setFailedMessage('올바르지 않은 기간입니다.')
			} else {
				setSearchData(searchInputData)
			}
		} else if(btnActions.active && btnActions.action === 'remove' && selectedProdPerf.length !== 0) {
			setShowDeleteModal(true)
		} else if(btnActions.active && btnActions.action === 'input') {
			setAddModMode(1)
			setShowAddModModal(true)
			setClickedProdPerf({
				...defaultClickedProdPerf,
				ymd: getDate()
			})
		}
		dispatch(buttonActions.clear())
	}, [btnActions.action, btnActions.active, dispatch])

	/* 조회 칸이 ''이면 왼쪽 테이블 되돌림 */
	useEffect(() => {
		if(searchInputData.item_nm.length === 0)
			setSearchData({
				...searchData,
				item_nm: ''
			})
	}, [searchInputData.item_nm.length])


	return (
		<>
			{/* 상단 메뉴 */}
			<div className="d-flex mt-2 flex-column">
				{/* 날짜 입력창 */}
				<div className="d-flex ms-3 my-1">
					<div className="d-flex align-content-center mx-3 py-lg-0">
						<label style={{ width: "50px", textAlign: "end" }} className="col-auto align-items-center my-1 me-2">일자</label>
						<div className="input-group input-group-sm">
							<input type="date" className="form-control"
								   value={convertDateWithHyphen(searchInputData.ymd1)}
								   onChange={e => handleChangeOnYMD(e, 1)}
							/>
						</div>
						<label className="col-auto align-items-center my-1 mx-2">~</label>
						<div className="input-group input-group-sm">
							<input type="date" className="form-control"
								   value={convertDateWithHyphen(searchInputData.ymd2)}
								   onChange={e => handleChangeOnYMD(e, 2)}
							/>
						</div>
					</div>
				</div>
				{/* 거래처 */}
				<div className="d-flex ms-3 my-1 justify-content-between">
					<div className="d-flex align-content-center mx-3 py-2 py-lg-0">
						<label style={{ width: "50px", textAlign: "end" }} className="col-auto align-items-center my-1 me-2">품명</label>
						<div className="input-group input-group-sm">
							<input ref={itemNmRef} type="text" className="form-control"
								   value={searchInputData.item_nm}
								   onChange={e => setSearchInputData({ ...searchInputData, item_nm: e.target.value })}
								   onKeyDown={handleEnterOnItemNm}/>
							<span className="input-group-text" onClick={handleClickOnSearchButton} style={{ cursor: 'pointer' }}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
									<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
								</svg>
							</span>
						</div>
					</div>
				</div>
			</div>

			<div className="my-3 mx-3 scrollbar table-wrapper">
				<table className="table table-sm table-bordered table-hover table-condensed">
					<thead className="sticky-head">
					<tr>
						{columns.map((column, index) =>
							<th key={index} style={column.style} scope="column">{column.name}</th>
						)}
					</tr>
					</thead>

					<tbody>
					<SelectedProdPerfContext.Provider value={{ clickedProdPerf, setClickedProdPerf, selectedProdPerf, setSelectedProdPerf, selectAll }}>
						<AddModModalContext.Provider value={{ setAddModMode, setShowAddModModal}}>
							<ProdPerfList data={data}/>
						</AddModModalContext.Provider>
						<OriginalProdPerfContext.Provider value={{ prodPerf, beforeProdPerf, addModMode, productData }} >
							<AddModModal show={showAddModModal} onHide={() => setShowAddModModal(false)} fetchProdPerf={fetchProdPerf} addModMode={addModMode} />
						</OriginalProdPerfContext.Provider>
					</SelectedProdPerfContext.Provider>
					</tbody>
				</table>
			</div>

			{/* 정보 삭제 모달 */}
			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered onExit={() => dispatch(buttonActions.clear())}>
				<Modal.Header closeButton>
					<Modal.Title>
						생산실적 삭제
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					선택하신 생산실적을 삭제하시겠습니까?
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary my-2" onClick={() => setShowDeleteModal(false)}>취소</button>
					<button className="btn btn-primary my-2" onClick={handleDeleteData}>삭제</button>
				</Modal.Footer>
			</Modal>

			<InfoModal show={showFailedModal} onHide={() => setShowFailedModal(false)} title={failedTitle}
					   message={failedMessage} onButtonClick={() => setShowFailedModal(false)} />

			<SmlProductModal product={productData} show={showSmlProductList} onHide={() => setShowSmlProductList(false)}
							 onSubmit={handleSubmitOnSmlProductSearch} initialSearchData={searchInputData.item_nm} />
		</>
	)
}

export default ProductionPerformance