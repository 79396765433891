import Flex from "../../../components/atom/Flex";
import MasterTitle from "../atom/MasterTitle";
import RadioGroup from "../atom/RadioGroup";

interface MasterHeaderProps {
  radioValue: string;
  setRadioValue: (value: string) => void;
}

export default function MasterHeader({ radioValue, setRadioValue }: MasterHeaderProps) {
  return (
    <Flex className='gap-2'>
      {/*<MasterTitle />*/}
      <RadioGroup radioValue={radioValue} setRadioValue={setRadioValue} />
    </Flex>
  )
}