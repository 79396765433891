import PrimaryButton from "../atom/buttons/PrimaryButton";
import DangerButton from "../atom/buttons/DangerButton";
import ButtonRow from "../atom/ButtonRow";

interface MasterFooterProps {
  searchData: string;
  setSearchData: (searchData: string) => void;
  handleClickAdd: () => void;
  handleClickSave: () => void;
  handleClickLogOut: () => void;
}

export default function MasterFooter({ searchData, setSearchData, handleClickAdd, handleClickSave, handleClickLogOut }: MasterFooterProps) {
  return (
    <ButtonRow>
      <input
        type="text"
        className="form-control"
        style={{ width: 160 }}
        value={searchData}
        onChange={e => setSearchData(e.target.value)}
        placeholder="검색어 입력"
      />
      <PrimaryButton onClick={handleClickAdd}>
        추가하기
      </PrimaryButton>
      <button
        className='btn btn-success'
        onClick={handleClickSave}
      >
        저장하기
      </button>
      <DangerButton
        onClick={handleClickLogOut}
      >
        로그아웃
      </DangerButton>
    </ButtonRow>
  );
}