import {ReactNode} from "react";

export function Column({ children }: { children: ReactNode }) {
  return (
    <div className='d-flex col mb-2'>
      {children}
    </div>
  )
}

export function ColumnLeft({ children }: { children: ReactNode }) {
  return (
    <div className='col-5'>
      {children}
    </div>
  )
}

export function ColumnRight({ children }: { children: ReactNode }) {
  return (
    <div className='col-7'>
      {children}
    </div>
  )
}