import React, {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import client from '../../axios'
import './styles.css'
import {Link} from 'react-router-dom'
import InfoModal from "../common-modals/info-modal";
import {Modal} from "react-bootstrap";
import PrimaryButton from "../../pages/master/atom/buttons/PrimaryButton";

const LoginForm = () => {

  /* 체크박스 레퍼런스 */
  const checkboxRef = useRef<HTMLInputElement>(null)

  /* 로그인 실패 시 뜨게 할 여부 */
  const [loginFailed, setLoginFailed] = useState({
    failed: false,
    message: ''
  })
  /* 계정 정보를 useState로 state를 만들어서 관리 */
  const [userInfo, setUserInfo] = useState({
    id: '',
    pwd: '',
    user_nm: '',
  });

  const [pwdSet, setPwdSet] = useState('');
  const [pwdFailed, setPwdFailed] = useState(false);
  const [openSetPwd, setOpenSetPwd] = useState(false);
  const [openSetPwdSuccess, setOpenSetPwdSuccess] = useState(false);

  /* 포커싱을 위해 useRef 사용 */
  const idInput = useRef<HTMLInputElement>(null),
    pwdInput = useRef<HTMLInputElement>(null),
    loginButton = useRef<HTMLButtonElement>(null)
  /* 라우팅을 위해 useNavigate 사용 */
  const navigate = useNavigate()

  /* 로컬 스토리지에 저장된 아이디가 있으면 그것 사용 */
  useEffect(() => {
    setUserInfo({...userInfo, id: localStorage.getItem("id") !== null ? localStorage.getItem("id") as string : ""})
  }, [])

  /* 로그인 폼에서 엔터 키를 눌렀을 때 실행되는 함수 */
  const onEnterKeyPress = async (e: any) => {
    if (e.key === 'Enter') {
      switch (document.activeElement?.id) {
        case 'id':
          pwdInput.current?.focus()
          break

        case 'pwd':
          loginButton.current?.focus()
          break

        default:
      }
    }
  }
  /* id나 pwd칸이 바뀌었을 때 실행될 함수, userInfo를 바꿈 */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    /* 어느 입력란이냐(id, pwd)에 따라 바뀌는 state 값이 다름 */
    setUserInfo({
      ...userInfo,
      [e.target.id]: e.target.value
    })
  }

  const handleChangeOnID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInfo({...userInfo, id: e.target.value})
  }

  /* 로그인 시행 */
  const requestLogin = async () => {
    try {
      /* 로그인 요청 */
      const response = await client.post('/api/auth/login-request', {
        id: userInfo.id,
        pwd: userInfo.pwd,
      })

      /* 응답 데이터의 result 값에 따른 처리 */
      switch (response.data.result) {
        /* 로그인 성공 */
        case 'success':
          if (checkboxRef.current) {
            if (checkboxRef.current.checked) {
              localStorage.setItem("id", userInfo.id)
            } else
              localStorage.removeItem("id")
          }
          if (response.data.payload.admin_gbn === '0') {
            navigate('/master')
          } else {
            navigate('/')
          }
          break

        /* 로그인 실패 */
        case 'failed':
          setLoginFailed({
            failed: true,
            message: '계정 정보를 찾을 수 없습니다.'
          })
          break

        case 'setPwd':
          setLoginFailed({
            failed: true,
            message: "비밀번호 설정이 필요합니다."
          });
          break;

        case 'error':
          setLoginFailed({
            failed: true,
            message: '서버에 문제가 발생하였습니다.'
          })
      }
    } catch (e: any) {
      setLoginFailed({
        failed: true,
        message: '에러가 발생하였습니다.'
      })
    }
  }

  async function handleCheckPwdSet() {
    try {
      const res = await client.get('/api/auth/check-pwd-set', { params: { id: userInfo.id }});
      if (res.data) {
        setLoginFailed({failed: false, message: ''});
        setOpenSetPwd(true);
      } else {
        setLoginFailed({
          failed: true,
          message: '비밀번호가 이미 설정되어 있습니다.'
        })
      }
    } catch(e) {
      setLoginFailed({
        failed: true,
        message: '계정이 존재하지 않습니다.'
      })
    }
  }

  async function handleSavePwd() {
    if (pwdSet.length === 0) {
      setPwdFailed(true);
    } else {
      await client.put('/api/auth/pwd', { id: userInfo.id, pwd: pwdSet });
      setPwdSet('');
      setPwdFailed(false);
      setOpenSetPwd(false);
      setOpenSetPwdSuccess(true);
    }
  }

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="text-center login-form-style">


          {/* 아이디, 비밀번호 입력칸 */}
          <div className="mb-1 mt-3 mx-1">
            <input id="id" type="text" onChange={handleChangeOnID} autoFocus onKeyDown={onEnterKeyPress} ref={idInput}
                   value={userInfo.id}
                   className={`form-control border-0 mb-2 border-bottom rounded-0 ${loginFailed.failed ? 'border-danger' : ''} shadow-none`}
                   placeholder="ID"/>

            <input id="pwd" type="password" onChange={onChange} onKeyDown={onEnterKeyPress} ref={pwdInput}
                   className={`form-control border-0 mb-2 border-bottom rounded-0 ${loginFailed.failed ? 'border-danger' : ''} shadow-none`}
                   placeholder="비밀번호"/>
          </div>
          <div className="mx-1 mb-3 text-start" style={{fontSize: '10pt'}}>
            {loginFailed.failed ? <label className="text-danger">{loginFailed.message}</label> : ''}
          </div>

          {/* 아이디 저장 체크박스 */}
          <div className="d-flex justify-content-between my-3 mx-3">
            <label>
              <input id="check" type="checkbox" ref={checkboxRef} className="form-check-input"
                     defaultChecked={localStorage.getItem("id") !== null}/>
              &nbsp;
              <label htmlFor="check">아이디 저장</label>
            </label>
            <div onClick={handleCheckPwdSet}>
              <label
                className="text-secondary"
                style={{textUnderlinePosition: 'under', pointerEvents: 'none', cursor: 'pointer'}}
              >
                비밀번호 생성
              </label>
            </div>
          </div>

          {/* 로그인 버튼 */}
          <button id="loginButton" className="w-100 btn btn-lg btn-secondary"
                  ref={loginButton} onClick={requestLogin}>로그인
          </button>
        </div>
      </div>

      <Modal
        show={openSetPwd}
        centered
      >
        <Modal.Header>비밀번호 설정하기</Modal.Header>
        <Modal.Body>
          <div className='d-flex justify-content-center mb-2'>
            <p style={{ width: 100 }} className='mb-0'>비밀번호: </p>
            <input
              type="password"
              className='form-control form-control-sm'
              style={{ width: 150 }}
              placeholder="비밀번호 입력"
              value={pwdSet}
              onChange={e => setPwdSet(e.target.value)}
            />
          </div>
          <p className='text-danger text-center' hidden={!pwdFailed}>
            비밀번호를 5자 이상 10자 이하로 설정해주십시오.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setOpenSetPwd(false)}>
            취소
          </button>
          <PrimaryButton onClick={handleSavePwd}>
            확인
          </PrimaryButton>
        </Modal.Footer>
      </Modal>

      <InfoModal show={openSetPwdSuccess} message="성공적으로 비밀번호가 설정되었습니다." onButtonClick={() => setOpenSetPwdSuccess(false)} />
    </>
  )
}

export default LoginForm