import {useNavigate} from "react-router-dom";
import {useState} from "react";
import AccountsTableBody from "../molecule/AccountsTableBody";
import {useDebounce} from "../../../hooks/useDebounce";
import client from "../../../axios";
import Table from "../atom/table/Table";
import TableHead, {TableColumn} from "../atom/table/TableHead";
import InfoModal from "../../../components/common-modals/info-modal";
import MasterFooter from "../molecule/MasterFooter";
import {Account} from "../interfaces/Account";

const columns: TableColumn[] = [
  {
    name: '사용자고유번호',
    width: '130px'
  },
  '거래처명',
  {
    name: 'ID',
    width: '150px'
  },
  {
    name: '비밀번호',
    width: '150px'
  },
  {
    name: '사용자명',
    width: '200px'
  },
  '관리자구분',
  '사용여부'
];

export default function AccountsDisplay() {
  const navigate = useNavigate();
  const [data, setData] = useState<Account[]>([]);
  const [addedData, setAddedData] = useState<Account[]>([]);
  const [searchData, setSearchData] = useState('');
  const [modifiedIndex, setModifiedIndex] = useState<number[]>([]);
  const debouncedText = useDebounce(searchData, 300);

  const [show, setShow] = useState(false);
  const [openFailed, setOpenFailed] = useState(false);

  const defaultData: Account = {
    usr_no: '',
    corp_nm: '',
    cus_no: '',
    admin_gbn: "1", id: "", on_gbn: "1", pwd: "", user_gbn: "1", user_nm: "",
  }

  async function reload() {
    setAddedData([]);
    setShow(false);
    const res = await client.get('/api/master/account', { params: { query: debouncedText } });
    setData(res.data);
  }

  function onModify(newIndex: number) {
    if (modifiedIndex.findIndex(i => i === newIndex) === -1) {
      setModifiedIndex(prev => [...prev, newIndex]);
    }
  }

  function handleAdd() {
    setAddedData(prev => [...prev, defaultData]);
  }

  async function handleSave() {
    try {
      await client.put('/api/master/account', {
        modified: modifiedIndex.map(mIndex => data[mIndex]),
        added: addedData
      });
      setShow(true);
    } catch(e) {
      setOpenFailed(true);
    }
  }

  async function handleLogout() {
    await client.delete('/api/auth/logout');
    navigate('/login');
  }

  return (
    <>
      <Table>
        <TableHead columns={columns} />
        <AccountsTableBody
          data={data}
          setData={setData}
          addedData={addedData}
          setAddedData={setAddedData}
          searchData={debouncedText}
          onModify={onModify}
        />
      </Table>
      <MasterFooter
        searchData={searchData}
        setSearchData={setSearchData}
        handleClickAdd={handleAdd}
        handleClickSave={handleSave}
        handleClickLogOut={handleLogout}
      />
      <InfoModal
        show={show}
        message="성공적으로 저장하였습니다."
        onButtonClick={() => {
          setShow(false);
          reload();
        }}
      />
      <InfoModal
        show={openFailed}
        message="저장에 실패하였습니다."
        onButtonClick={() => {
          setOpenFailed(false);
          reload();
        }}
      />
    </>
  )
}