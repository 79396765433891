interface OnGbnSelectionProps {
  value: string;
  onChange: (value: string) => void;
}

export default function OnGbnSelection(props: OnGbnSelectionProps) {
  return (
    <select value={props.value} onChange={e => props.onChange(e.target.value)}>
      <option value="0">미사용</option>
      <option value="1">사용</option>
    </select>
  )
}