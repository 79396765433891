import {SETDEC, decTypes} from '../actions/dec'

const initialState = {
  dec: 3
}

type DecState = typeof initialState

/* reducer */
const dec = (state: DecState = initialState, action: decTypes) => {
  switch (action.type) {
    case SETDEC:
      return {
        dec: action.payload
      }

    default:
      return state
  }
}

export default dec