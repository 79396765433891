import {ComponentPropsWithoutRef} from "react";

interface SecondaryButtonProps extends ComponentPropsWithoutRef<'button'> {}

export default function SecondaryButton(props: SecondaryButtonProps) {
  return (
    <button {...props} className='btn btn-secondary'>
      {props.children}
    </button>
  )
}