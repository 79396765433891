interface AdminGbnSelectionProps {
  value: string;
  onChange: (value: string) => void;
}

export default function AdminGbnSelection(props: AdminGbnSelectionProps) {
  return (
    <select value={props.value} onChange={e => props.onChange(e.target.value)}>
      <option value="0">마스터</option>
      <option value="1">관리자</option>
      <option value="2">일반사용자</option>
    </select>
  )
}