import {ComponentPropsWithoutRef} from "react";
import Cell from "./Cell";

interface CenterCellProps extends ComponentPropsWithoutRef<'td'> {}

export default function CenterCell(props: CenterCellProps) {
  return (
    <Cell {...props} style={{
      textAlign: 'center'
    }} />
  )
}