import React from "react";

export const getDate = (): string => {
	const currentTime = new Date()

	return currentTime.getFullYear().toString() +
		('0' + (1 + currentTime.getMonth())).slice(-2) +
		('0' + currentTime.getDate()).slice(-2)
}

export const isSaupNo = (saup_no: string) => {
	if(isNaN(parseInt(saup_no))) {
		return false
	} else if(saup_no.length === 0) {
		return false
	} else if(saup_no.length === 10) {
		/* number로 변환 */
		const saup_num = saup_no.split('').map(item => parseInt(item))
		const multiply = [1, 3, 7, 1, 3, 7, 1, 3, 5]
		let checkSum = 0

		for(let i = 0; i < multiply.length; i++)
			checkSum += saup_num[i] * multiply[i]

		checkSum += Math.floor((multiply[8] * saup_num[8]) / 10)
		checkSum = 10 - (checkSum % 10)
		return saup_num[9] === checkSum;
	} else {
		return false
	}
}

export function setData<T extends Object>(setter: React.Dispatch<React.SetStateAction<T>>) {
	return (key: keyof T, value: T[keyof T]) => setter(prev => ({ ...prev, [key]: value }))
}

export function range(start: number, end: number) {
	const arr: Array<number> = [];

	for (let i = start; i < end; i++) {
		arr.push(i);
	}

	return arr;
}