import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {Accordion} from 'react-bootstrap'

import * as currentPageActions from '../../redux/actions/current-page'
//import {RootReducer} from '../../redux/reducers'

const Sidebar = () => {

  /* 현재 페이지 항목 들고 옴(색 변경을 위하여 )*/
  //const currentPage = useSelector((state: RootReducer) => state.currentPage, shallowEqual)
  //let curPg = currentPage.current_page
  /* 디스패치 사용 */
  const dispatch = useDispatch()

  /* 현재 액티브된 항목 */
  const [currentComponent, setCurrentComponent] = useState('')

  /* 클릭 시 디스패치 */
  /* 입출고관리 */
  const handleInputBuy = () => dispatch(currentPageActions.inputBuy())
  const handleInputSell = () => dispatch(currentPageActions.inputSell())
  /* 기준정보 */
  const handleUserCom = () => dispatch(currentPageActions.usercom())
  const handleStandardCode = () => dispatch(currentPageActions.standardCode())
  const handleCustomer = () => dispatch(currentPageActions.customer())
  const handleProduct = () => dispatch(currentPageActions.product())
  /* 생산관리 */
  const handleAddRecipe = () => dispatch(currentPageActions.addRecipe())
  const handleProdPerf = () => dispatch(currentPageActions.prodPerf())
  const handleProdDaily = () => dispatch(currentPageActions.prodDaily())
  /* 재고관리 */
  const handleInitialStock = () => dispatch(currentPageActions.initialStock())
  const handleStockStatus = () => dispatch(currentPageActions.stockStatus())
  const handleStockPay = () => dispatch(currentPageActions.stockPay())
  const handleMatPay = () => dispatch(currentPageActions.matPay())
  /* 환경설정 */
  const handleSettings = () => dispatch(currentPageActions.settings())

  /* 입출고관리 메뉴 */
  const inOutManage = [
    {name: '입고(구매) 입력', handleClick: handleInputBuy, val: 'input-buy'},
    {name: '출고(판매) 입력', handleClick: handleInputSell, val: 'input-sell'},
  ]

  /* 기준정보 항목들 */
  const standardInfo = [
    {name: '사용회사 정보 등록', handleClick: handleUserCom, val: 'user-com'},
    {name: '기준코드 등록', handleClick: handleStandardCode, val: 'standard-code'},
    {name: '거래처 등록', handleClick: handleCustomer, val: 'customer'},
    {name: '품목 등록 ', handleClick: handleProduct, val: 'product'}
  ]

  /* 생산관리 항목들 */
  const prodManage = [
    {name: '레시피 등록', handleClick: handleAddRecipe, val: 'add-recipe'},
    {name: '생산실적 입력', handleClick: handleProdPerf, val: 'prod-perf'},
    {name: '생산일보', handleClick: handleProdDaily, val: 'prod-daily'},
  ]

  /* 재고관리 항목들 */
  const stockManage = [
    {name: '기초재고 등록', handleClick: handleInitialStock, val: 'initial-stock'},
    {name: '재고현황', handleClick: handleStockStatus, val: 'stock-status'},
    {name: '재고수불부', handleClick: handleStockPay, val: 'stock-pay'},
    {name: '원료수불부', handleClick: handleMatPay, val: 'mat-pay'}
  ]

  const settings = [
    {name: '환경설정', handleClick: handleSettings, val: 'settings'}
  ]

  return (
    <nav id="sidebarMenu" className="p-0 d-md-block position-fixed collapse border-end"
         style={{width: '250px', height: 800}}>
      <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen className="border-0 col-12 position-sticky">

        <Accordion.Item eventKey="0" className="rounded-0 border-0 py-1">
          <Accordion.Header>입출고관리</Accordion.Header>
          <Accordion.Body className="d-grid px-0 py-0">
            <div className="btn-group-vertical">
              {inOutManage.map((content, index) =>
                <button key={`in_out_manage_${index}`}
                        className="btn border-0 rounded-0 shadow-none sidebar-content-button"
                        onClick={() => {
                          content.handleClick();
                          setCurrentComponent(content.val)
                        }}>
                  <div className={`${currentComponent === content.val ? "bg-gray" : ""} d-inline p-2 rounded-2`}>
                    {content.name}
                  </div>

                </button>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1" className="rounded-0 border-0 py-1">
          <Accordion.Header>생산관리</Accordion.Header>
          <Accordion.Body className="d-grid gap-2 px-0 py-0">
            <div className="btn-group-vertical">
              {prodManage.map((content, index) =>
                <button key={`prod_manage_${index}`}
                        className="btn border-0 rounded-0 shadow-none sidebar-content-button"
                        onClick={() => {
                          content.handleClick();
                          setCurrentComponent(content.val)
                        }}>
                  <div className={`${currentComponent === content.val ? "bg-gray" : ""} d-inline p-2 rounded-2`}>
                    {content.name}
                  </div>
                </button>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2" className="rounded-0 border-0 py-1">
          <Accordion.Header>재고관리</Accordion.Header>
          <Accordion.Body className="d-grid gap-2 px-0 py-0">
            <div className="btn-group-vertical">
              {stockManage.map((content, index) =>
                <button key={`stock_manage_${index}`}
                        className="btn border-0 rounded-0 shadow-none sidebar-content-button"
                        onClick={() => {
                          content.handleClick();
                          setCurrentComponent(content.val)
                        }}>
                  <div className={`${currentComponent === content.val ? "bg-gray" : ""} d-inline p-2 rounded-2`}>
                    {content.name}
                  </div>
                </button>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3" className="rounded-0 border-0 py-1">
          <Accordion.Header>기준정보</Accordion.Header>
          <Accordion.Body className="d-grid px-0 py-0">
            <div className="btn-group-vertical">
              {standardInfo.map((content, index) =>
                <button key={`standard_info_${index}`}
                        className="btn border-0 rounded-0 shadow-none sidebar-content-button"
                        onClick={() => {
                          content.handleClick();
                          setCurrentComponent(content.val)
                        }}>
                  <div className={`${currentComponent === content.val ? "bg-gray" : ""} d-inline p-2 rounded-2`}>
                    {content.name}
                  </div>
                </button>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4" className="rounded-0 border-0 nostyle py-1"
                        onClick={() => {
                          settings[0].handleClick();
                          setCurrentComponent(settings[0].val)
                        }}
        >
          <Accordion.Header>
            <div className={`${currentComponent === settings[0].val ? "bg-gray" : ""} d-inline p-2 rounded-2`}>
              {settings[0].name}
            </div>
          </Accordion.Header>
        </Accordion.Item>

      </Accordion>

    </nav>
  )
}

export default Sidebar