import React, {useEffect, useRef} from "react";

interface EditableCellProps {
  value: string;
  isEditing: boolean;
  onClick: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  defaultMessage?: string;
}

export default function EditableCell({ value, isEditing, onClick, onChange, defaultMessage }: EditableCellProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if(isEditing && inputRef.current) {
      setTimeout(() => inputRef.current?.focus(), 100);
    }
  }, [isEditing]);

  return isEditing ? (
    <>
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onClick}
      />
    </>
  ) : (
    <div onClick={onClick} style={{ width: '100%', height: '100%' }}>
      {(defaultMessage && value.length === 0) ? <div className="m-0 text-secondary">{defaultMessage}</div> : value}
    </div>
  );
}