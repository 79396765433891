import React, {ChangeEvent, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {IAddModal, ICustomer} from '../../../../interfaces/standard-info/i-customer'
import DaumPostcode, {Address} from 'react-daum-postcode'
import {convertPhoneNoReadable} from '../../../../../lib/converting-func'
import '../styles.css'

const AddModal = ({ show, onHide, onAdd, useCategory }: IAddModal) => {

	const defaultRow: ICustomer = {
		corp: '',
		cus: '',
		cus_usr: '',
		cus_nm_o: '',
		cus_nm: '',
		saup_no: '',
		owner: '',
		up: '',
		jong: '',
		tel: '',
		fax: '',
		zip: '',
		addr: '',
		tax_email: '',
		bank_owner: '',
		bigo: '',
		use_gbn: '1',
	}
	const defaultError = {
		cus_nm_o: true,
		saup_no: false
	}

	const [ customerRow, setCustomerRow ] = useState<ICustomer>(defaultRow)
	const [ daumPost, setDaumPost ] = useState(false)
	const [ errorOnInput, setErrorOnInput ] = useState(defaultError)
	const [ errorMsg, setErrorMsg ] = useState('')
	const [ showErrorMsg, setShowErrorMsg ] = useState(false)
	/* 변경 사항 저장 */
	const [ showSaveModal, setShowSaveModal ] = useState(false)
	/* 변경되었는지 확인 */
	const [ isChanged, setIsChanged ] = useState(false)

	const initialize = () => {
		setCustomerRow(defaultRow)
		setErrorOnInput(defaultError)
		setErrorMsg('')
		setShowErrorMsg(false)
	}

	useEffect(() => {
		setTimeout(() => document.getElementById('cus_nm_o')?.focus(), 200)
	}, [show])

	/* 엔터키 입력시 다음 입력란으로 넘어감 */
	const handleEnter = (e: any) => {
		if(e.key === 'Enter') {
			const form = e.target.form
			const index = Array.prototype.indexOf.call(form, e.target)
			const id = form.elements[index].id

			if(id === 'fax' || id === 'addr')
				form.elements[index + 2].focus()
			else if (id !== 'use_gbn')
				form.elements[index + 1].focus()

			e.preventDefault()
		}
	}

	/* 거래처명 입력 확인 */
	const handleChangeOnCusNmO = (e: any) => {
		setCustomerRow({ ...customerRow, cus_nm_o: e.target.value, cus_nm: e.target.value })
		setIsChanged(true)
		if(e.target.value === '')
			setErrorOnInput({
				...errorOnInput,
				cus_nm_o: true,
			})
		else
			setErrorOnInput({
				...errorOnInput,
				cus_nm_o: false,
			})
	}

	/* 주소 빈 칸일 시 우편번호 없애기 */
	const handleChangeOnAddr = (e: any) => {
		setIsChanged(true)
		if(e.target.value === '') {
			return setCustomerRow({ ...customerRow, addr: e.target.value, zip: e.target.value })
		}
		return setCustomerRow({ ...customerRow, addr: e.target.value })
	}

	/* 다음 주소 api 사용 */
	const handleComplete = (data: Address) => {
		let fullAddress = data.address;
		let extraAddress = ''

		if (data.addressType === 'R') {
			if (data.bname !== '') {
				extraAddress += data.bname;
			}
			if (data.buildingName !== '') {
				extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName)
			}
			fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '')
		}

		setCustomerRow({
			...customerRow,
			addr: fullAddress,
			zip: data.zonecode
		})

		setTimeout(() => document.getElementById('addr')?.focus(), 200)
		setDaumPost(false)
	}

	/* 사업자등록번호 바뀔 때 입력란 확인 */
	const handleChangeOnSaupNo = (e: ChangeEvent<HTMLInputElement>) => {
		let saup_no = e.target.value.slice(0, 12)
		const pure_saup_no = saup_no.split('-').reduce((prev, cur) => prev + cur, '')
		setIsChanged(true)

		if(saup_no !== '') {
			if(saup_no.charAt(saup_no.length - 1) === '-') {
				saup_no = saup_no.slice(0, saup_no.length - 1)
			}

			if(pure_saup_no.length === 4) {
				saup_no = pure_saup_no.slice(0, 3) + '-' + pure_saup_no.slice(3)
			} else if(pure_saup_no.length === 6) {
				saup_no = pure_saup_no.slice(0, 3) + '-' + pure_saup_no.slice(3, 5) + '-' + pure_saup_no.slice(5)
			}
		}

		if(pure_saup_no.length === 0) {
			setErrorOnInput({...errorOnInput, saup_no: false})
		} else if(isNaN(parseInt(pure_saup_no))) {
			setErrorOnInput({...errorOnInput, saup_no: true})
		} else if(pure_saup_no.length === 10) {
			/* number로 변환 */
			const saup_num = pure_saup_no.split('').map(item => parseInt(item, 10))
			const multiply = [1, 3, 7, 1, 3, 7, 1, 3, 5]
			let checkSum = 0

			for(let i = 0; i < multiply.length; i++)
				checkSum += saup_num[i] * multiply[i]

			checkSum += Math.floor((multiply[8] * saup_num[8]) / 10)
			checkSum = 10 - (checkSum % 10)
			if(saup_num[9] === checkSum) {
				setErrorOnInput({...errorOnInput, saup_no: false})
			} else {
				setErrorOnInput({...errorOnInput, saup_no: true})
			}
		} else {
			setErrorOnInput({ ...errorOnInput, saup_no: true })
		}

		setCustomerRow({ ...customerRow, saup_no  })
	}

	/* 전화번호 입력 */
	const handleChangeTelFax = (e: ChangeEvent<HTMLInputElement>) => {
		let value =  convertPhoneNoReadable(e.target.value)
		setIsChanged(true)
		if(e.target.id === 'tel') {
			setCustomerRow({ ...customerRow, tel: value })
		} else {
			setCustomerRow({ ...customerRow, fax: value })
		}
	}

	const handleClickOnButton = () => {
		setIsChanged(false)
		if(errorOnInput.cus_nm_o) {
			setErrorMsg('거래처명을 입력해주세요.')
			setShowErrorMsg(true)
		} else if(errorOnInput.saup_no) {
			setErrorMsg('사업자등록번호를 정확히 입력해주세요.')
			setShowErrorMsg(true)
		} else {
			setShowErrorMsg(false)
			onAdd({
				...customerRow,
				saup_no: customerRow.saup_no.split('-').reduce((prev, cur) => prev + cur, '')
			})
			onHide()
		}
	}

	/* 폼 데이터 초기화 후 숨기기 */
	const handleHide = () => {
		initialize()
		onHide()
	}

	/* 변경 사항 저장 */
	const handleSaveData = () => {
		handleClickOnButton()
		setShowSaveModal(false)
	}

	return (
		<Modal show={show} onHide={() => {
			if(isChanged)
				setShowSaveModal(true)
			else
				handleHide()
		}} centered size="lg">
			<Modal.Header closeButton>
				<Modal.Title>
					거래처 추가
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="card-body">
					<form onSubmit={e => e.preventDefault()}>
						{/* 거래처(코드) */}
						<div className="input-group input-group-sm my-3">
							<span className="input-group-text cus-addon-size">거래처코드</span>
							<input id="cus" type="text" className="form-control text-center" onKeyDown={handleEnter}
								   value={customerRow.cus_usr} readOnly disabled style={{ maxWidth: '120px' }}/>
						</div>

						{/* 거래처명 */}
						<div className="input-group input-group-sm my-3">
							<span className="input-group-text cus-addon-size">거래처명</span>
							<input id="cus_nm_o" type="text" className="form-control" value={customerRow.cus_nm_o} onKeyDown={handleEnter}
								   onChange={handleChangeOnCusNmO} />
						</div>

						{/* 거래처약칭 */}
						<div className="input-group input-group-sm my-3">
							<span className="input-group-text cus-addon-size">거래처약칭</span>
							<input id="cus_nm" type="text" className="form-control" value={customerRow.cus_nm} onKeyDown={handleEnter}
								   onChange={e => {
									   setCustomerRow({...customerRow, cus_nm: e.target.value})
									   setIsChanged(true)
								   }} />
						</div>

						{/* 사업자등록번호 */}
						<div className="input-group input-group-sm my-3">
							<span className="input-group-text cus-addon-size">사업자등록번호</span>
							<input id="saup_no" type="text" className="form-control" value={customerRow.saup_no} onKeyDown={handleEnter}
								   onChange={handleChangeOnSaupNo} />
						</div>

						{/* 대표자명 */}
						<div className="input-group input-group-sm my-3">
							<span className="input-group-text cus-addon-size">대표자명</span>
							<input id="owner" type="text" className="form-control" value={customerRow.owner} style={{ maxWidth: '200px' }}
								   onChange={e => {
									   setCustomerRow({...customerRow, owner: e.target.value})
									   setIsChanged(true)
								   }} onKeyDown={handleEnter}/>
						</div>

						{/* 업태 */}
						<div className="input-group input-group-sm my-3">
							<span className="input-group-text cus-addon-size">업태</span>
							<input id="up" type="text" className="form-control" value={customerRow.up} onKeyDown={handleEnter}
								   onChange={e => {
									   setCustomerRow({...customerRow, up: e.target.value})
									   setIsChanged(true)
								   }} />
						</div>

						{/* 종목 */}
						<div className="input-group input-group-sm my-3">
							<span className="input-group-text cus-addon-size">종목</span>
							<input id="jong" type="text" className="form-control" value={customerRow.jong} onKeyDown={handleEnter}
								   onChange={e => {
									   setCustomerRow({...customerRow, jong: e.target.value})
									   setIsChanged(true)
								   }} />
						</div>

						{/* 전화번호 */}
						<div className="input-group input-group-sm my-3">
							<span className="input-group-text cus-addon-size">전화번호</span>
							<input id="tel" type="text" className="form-control" value={customerRow.tel}
								   onChange={handleChangeTelFax} onKeyDown={handleEnter} />
						</div>

						{/* 팩스번호 */}
						<div className="input-group input-group-sm my-3">
							<span className="input-group-text cus-addon-size">팩스번호</span>
							<input id="fax" type="text" className="form-control" value={customerRow.fax}
								   onChange={handleChangeTelFax} onKeyDown={handleEnter} />
						</div>

						{/* 주소 */}
						<div className="input-group input-group-sm my-3">
							<span className="input-group-text cus-addon-size">주소</span>
							<input id="addr" className="form-control" value={customerRow.addr}
								   onChange={handleChangeOnAddr} onKeyDown={handleEnter}/>
							<button id="zip" className="btn btn-sm btn-outline-secondary"
									onClick={() => setDaumPost(true)}>
								{customerRow.zip === '' ? '우편번호 찾기' : customerRow.zip}
							</button>
						</div>

						{/* 주소 입력 모달 */}
						<Modal centered show={daumPost} onHide={() => setDaumPost(false)}>
							<Modal.Header closeButton>
								<Modal.Title>
									주소 입력
								</Modal.Title>
							</Modal.Header>

							<Modal.Body>
								<DaumPostcode onComplete={handleComplete}/>
							</Modal.Body>
						</Modal>

						{/* 홈택스이메일 */}
						<div className="input-group input-group-sm my-3">
							<span className="input-group-text cus-addon-size">홈택스이메일</span>
							<input id="tax_email" type="text" className="form-control" value={customerRow.tax_email} onKeyDown={handleEnter}
								   onChange={e => {
									   setCustomerRow({...customerRow, tax_email: e.target.value})
									   setIsChanged(true)
								   }} />
						</div>

						{/* 결제계좌정보 */}
						<div className="input-group input-group-sm my-3">
							<span className="input-group-text cus-addon-size">결제계좌정보</span>
							<input id="bank_owner" type="text" className="form-control" value={customerRow.bank_owner} onKeyDown={handleEnter}
								   onChange={e => {
									   setCustomerRow({...customerRow, bank_owner: e.target.value})
									   setIsChanged(true)
								   }} />
						</div>

						{/* 비고 */}
						<div className="input-group input-group-sm my-3">
							<span className="input-group-text cus-addon-size">비고</span>
							<input id="bigo" type="text" className="form-control" value={customerRow.bigo} onKeyDown={handleEnter}
								   onChange={e => {
									   setCustomerRow({...customerRow, bigo: e.target.value})
									   setIsChanged(true)
								   }} />
						</div>

						{/* 사용여부 */}
						<div className="input-group input-group-sm my-3">
							<span className="input-group-text cus-addon-size">사용여부</span>
							<select id="use_gbn" defaultValue={customerRow.use_gbn} className="form-select form-select-sm"  onKeyDown={handleEnter}
									style={{ maxWidth: '150px' }} onChange={e => {
								setCustomerRow({...customerRow, use_gbn: e.target.value})
								setIsChanged(true)
							}}>
								{useCategory.slice(1).map((category, index) =>
									<option key={index} value={category.value}>{`${index + 1}. ${category.name}`}</option>
								)}
							</select>
						</div>
					</form>
				</div>
				<div className="d-flex justify-content-end">
					<label className="me-3 text-danger" hidden={!showErrorMsg}>{errorMsg}</label>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="secondary" onClick={handleHide}>닫기</Button>
				<Button variant="primary" onClick={handleClickOnButton}>추가</Button>
			</Modal.Footer>

			{/* 변경 내용 저장 모달 */}
			<Modal show={showSaveModal} onHide={() => {
				setShowSaveModal(false)
			}} centered>
				<Modal.Header closeButton>
					<Modal.Title>
						입력 사항 저장
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					입력된 정보를 저장하시겠습니까?
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary my-2" onClick={() => {
						setShowSaveModal(false)
						setIsChanged(true)
						onHide()
					}}>취소</button>
					<button className="btn btn-primary my-2" onClick={handleSaveData}>저장</button>
				</Modal.Footer>
			</Modal>
		</Modal>
	)
}

export default AddModal