import {Customer} from "../interfaces/Customer";
import {useEffect, useMemo, useRef, useState} from "react";
import client from "../../../axios";
import CenterCell from "../atom/table/CenterCell";
import Cell from "../atom/table/Cell";
import EditableCell from "../atom/EditableCell";

interface CustomersTableBodyProps {
  data: Customer[];
  setData: (data: Customer[]) => void;
  addedData: Customer[];
  setAddedData: (data: Customer[]) => void;
  searchData: string;
  onModify: (newIndex: number) => void;
}

export default function CustomersTableBody(props: CustomersTableBodyProps) {
  const tableRef = useRef<HTMLTableSectionElement | null>(null);
  const tableData = useMemo(() => props.data.concat(props.addedData), [props.data, props.addedData]);
  const [editingCellRow, setEditingCellRow] = useState<number | null>(null);

  function handleClickRow(rowIndex: number) {
    setEditingCellRow(rowIndex);
  }

  function handleBlur() {
    setEditingCellRow(null);
  }

  function handleClickOutside(e: MouseEvent) {
    if (tableRef.current && !tableRef.current.contains(e.target as Node)) {
      handleBlur();
    }
  }

  function handleChange(key: keyof Customer, value: Customer[keyof Customer], i: number) {
    const isAddedData = tableData[i].corp.length === 0;
    const tmpData = Array.from(isAddedData ? props.addedData : props.data);
    const ipp = isAddedData ? (i - props.data.length) : i;

    if (typeof value === "string" && key !== 'serv_seq') {
      tmpData[ipp][key] = value;
    }

    if (isAddedData) {
      props.setAddedData(tmpData);
    } else {
      props.setData(tmpData);
      props.onModify(ipp);
    }
  }

  useEffect(() => {
    client
      .get('/api/master/customer', { params: { query: props.searchData } })
      .then(res => props.setData(res.data));
  }, [props.searchData]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <tbody ref={tableRef}>
    {tableData.map((customer, i) =>
      <tr key={i}>
        <CenterCell>
          {customer.corp}
        </CenterCell>
        <Cell>
          {
            i < props.data.length ?
              customer.corp_nm :
              <EditableCell
                value={customer.corp_nm}
                isEditing={i === editingCellRow}
                onClick={() => handleClickRow(i)}
                onChange={e => handleChange("corp_nm", e.target.value, i)}
              />
          }
        </Cell>
      </tr>
    )}
    </tbody>
  )
}